<!-- 5G通信学院 -->
<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: center; background: #F6F8F7;">
            <div style="width: 100%; max-width: 1440px; padding: 0 100px 50px 100px;">
                <div style="padding: 24px 40px 40px 40px; margin-top: -60px; border-radius: 4px; background: #FFF;">
                    <a-space class="nav">
                        <p style="color: #282A29;">教学单位</p>
                        <p style="color: #282A29;">&gt;</p>
                        <p style="color: #028848;">{{ checkedConfig.name }}</p>
                    </a-space>
                    <div @click="gotoArticle(articles.college.intro?.id)"
                        style="display: flex; align-items: flex-start; cursor: pointer;">
                        <div style="width: 892%;">
                            <p style="
                                    margin-top: 40px;
                                    font-size: 26px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 32px;
                            ">{{ checkedConfig.name }}</p>
                            <div class="lines-words" style="
                                    margin-top: 20px;
                                    -webkit-line-clamp: 2;
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #282A29;
                                    line-height: 26px;
                            ">{{ articles.college.intro?.intro }}</div>
                        </div>
                        <div style="width: 122%;"></div>
                        <div style="width: 146%;">
                            <img :src="backgroundImage.bg1" alt=""
                                style="width: 100%; height: 146px; object-fit: contain;">
                        </div>
                    </div>
                </div>
                <div style="padding: 24px 40px 40px 40px; margin-top: -60px; border-radius: 4px; background: #FFF;">
                </div>
            </div>
        </div>
        <div :style="{ backgroundImage: `url(${backgroundImage.bg2})` }"
            style="display: flex; justify-content: center; align-items: center;">
            <div style="width: 100%; max-width: 1440px; padding: 40px 100px 50px 100px;">
                <div style="text-align: center;">
                    <p style="
                            font-size: 28px;
                            font-weight: 600;
                            color: #282A29;
                            line-height: 50px;
                    ">专业详情</p>
                </div>
                <div style="margin-top: 24px; display: flex; justify-content: space-between;">
                    <div @mouseenter="mouseIn('left')" @mouseleave="mouseOut('left')"
                        style="width: 56px; height: 56px; flex-shrink: 0;">
                        <img v-show="!viewCtl.hover.left" @click="toggleTable('left')"
                            :src="backgroundImage.left1" alt=""
                            style="width: 100%; height: 100%; object-fit: cover;">
                        <img v-show="viewCtl.hover.left" @click="toggleTable('left')"
                            :src="backgroundImage.left2" alt=""
                            style="width: 100%; height: 100%; object-fit: cover;">
                    </div>
                    <div style="width: 36px; flex-shrink: 0;"></div>
                    <div style="overflow-x: auto;" id="major-content">
                        <a-space>
                            <div v-for="(major, index) in articles.college.majors" :key="index" :id="'major-' + index">
                                <div v-show="viewCtl.checked === index"
                                    style="padding: 16px 20px; border-radius: 4px; flex-shrink: 0; display: flex; justify-content: center; align-items: center;"
                                    :style="{ backgroundColor: checkedConfig.color }"
                                >
                                    <p style="
                                            white-space: nowrap;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: #FFFFFF;
                                            line-height: 24px;
                                    ">{{ major.label }}</p>
                                </div>
                                <div @click="toggleIndex(index)" v-show="viewCtl.checked !== index"
                                    style="padding: 16px 20px; border-radius: 4px; border: 1px solid #FFF; flex-shrink: 0; display: flex; justify-content: center; align-items: center;">
                                    <p style="
                                            white-space: nowrap;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 24px;
                                    ">{{ major.label }}</p>
                                </div>
                            </div>
                        </a-space>
                    </div>
                    <div style="width: 36px; flex-shrink: 0;"></div>
                    <div @mouseenter="mouseIn('right')" @mouseleave="mouseOut('right')"
                        style="width: 56px; height: 56px; flex-shrink: 0;">
                        <img v-show="!viewCtl.hover.right" 
                            :src="backgroundImage.right1"
                            alt="" style="width: 100%; height: 100%; object-fit: cover;">
                        <img v-show="viewCtl.hover.right" @click="toggleTable('right')"
                            :src="backgroundImage.right2"
                            alt="" style="width: 100%; height: 100%; object-fit: cover;">
                    </div>
                </div>
                <div style="margin-top: 24px; display: flex;">
                    <div @click="gotoArticle(articles.college.majors[viewCtl.checked]?.article?.majorIntro?.id)"
                        style="width: 454%; background-color: #FFF; height: 564px; cursor: pointer;">
                        <img v-if="articles.college.majors[viewCtl.checked]?.article?.majorIntro?.coverUrl"
                            :src="(articles.college.majors[viewCtl.checked].article.majorIntro.coverUrl)" alt=""
                            style="width: 100%; height: 308px; object-fit: cover; padding: 10px;">
                        <div style="text-align: center; margin-top: 24px;">
                            <p style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 24px;
                            ">专业简介</p>
                        </div>
                        <div style="
                            padding: 12px 24px 24px 24px;
                        ">
                            <p class="lines-words" style="
                                    -webkit-line-clamp: 7;
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #282A29;
                                    line-height: 26px;
                            ">
                                {{ articles.college.majors[viewCtl.checked]?.article?.majorIntro?.intro }}
                            </p>
                        </div>
                    </div>
                    <div style="width: 20%;"></div>
                    <div style="width: 768%;">
                        <div @click="gotoArticle(articles.college.majors[viewCtl.checked]?.article?.majorDirection?.id)"
                            style="padding: 24px; background-color: #FFF; height: 174px; cursor: pointer;">
                            <p style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 24px;
                            ">专业方向</p>
                            <div style="margin-top: 12px;">
                                <p class="lines-words" style="
                                        -webkit-line-clamp: 3;
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: #282A29;
                                        line-height: 26px;
                                ">
                                    {{ articles.college.majors[viewCtl.checked]?.article?.majorDirection?.intro }}
                                </p>
                            </div>
                        </div>
                        <div @click="gotoArticle(articles.college.majors[viewCtl.checked]?.article?.majorCourse?.id)"
                            style="padding: 24px; background-color: #FFF; height: 174px; margin-top: 21px; cursor: pointer;">
                            <p style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 24px;
                            ">主干课程</p>
                            <div style="margin-top: 12px;">
                                <p class="lines-words" style="
                                        -webkit-line-clamp: 3;
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: #282A29;
                                        line-height: 26px;
                                ">
                                    {{ articles.college.majors[viewCtl.checked]?.article?.majorCourse?.intro }}
                                </p>
                            </div>
                        </div>
                        <div @click="gotoArticle(articles.college.majors[viewCtl.checked]?.article?.majorEmployment?.id)"
                            style="padding: 24px; background-color: #FFF; height: 174px; margin-top: 21px; cursor: pointer;">
                            <p style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 24px;
                            ">就业前景</p>
                            <div style="margin-top: 12px;">
                                <p class="lines-words" style="
                                        -webkit-line-clamp: 3;
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: #282A29;
                                        line-height: 26px;
                                ">
                                    {{ articles.college.majors[viewCtl.checked]?.article?.majorEmployment?.intro }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img :src="backgroundImage.bg3" alt="" style="width: 100%; height: 320px; object-fit: cover;">
        <div
            style="display: flex; justify-content: center; align-items: center; margin-top: -240px; background: #F6F8F7;">
            <div style="width: 100%; max-width: 1440px; padding: 40px 100px 50px 100px;">
                <div style="text-align: center;">
                    <p style="
                            font-size: 28px;
                            font-weight: 600;
                            color: #FFFFFF;
                            line-height: 50px;
                    ">学院动态</p>
                </div>
                <div style="background-color: #FFF; padding: 40px 32px; margin-top: 24px;">
                    <div v-for="(record, index) in articles.college.news.records" :key="index"
                        @click="gotoArticle(record.id)" class="hover-item"
                        style="background: #F6F8F7; border-radius: 4px; padding: 24px; display: flex; justify-content: space-between; margin-top: 20px; cursor: pointer;">
                        <div style="width: 224%;">
                            <div style="width: 100%; height: 150px; overflow: hidden;">
                                <img class="hover-plus" :src="record.coverUrl" alt=""
                                    style="width: 100%; height: 100%; object-fit: cover;">
                            </div>
                            <div
                                style="height: 52px; width: 73px; opacity: 0.7; margin-top: -64px; padding: 6px 0 0 12px;"
                                :style="{ backgroundColor: checkedConfig.color }"    
                            >
                                <p style="
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: #FFFFFF;
                                        line-height: 22px;
                                ">{{ record.createTime ? (record.createTime.split(' ')[0].split('-')[1] + '/' +
                                    record.createTime.split(' ')[0].split('-')[2] ) : '' }}</p>
                                <p style="
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                        line-height: 18px;
                                ">{{ record.createTime ? record.createTime.split(' ')[0].split('-')[0] : '' }}</p>
                            </div>
                        </div>
                        <div style="width: 24%;"></div>
                        <div style="width: 944%;">
                            <p class="hover-color lines-words" style="
                                    -webkit-line-clamp: 1;
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 24px;
                            ">{{ record.title ? record.title : '' }}</p>
                            <p class="lines-words" style="
                                    margin-top: 12px;
                                    -webkit-line-clamp: 3;
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #282A29;
                                    line-height: 26px;
                            ">{{ record.intro ? record.intro : '' }}</p>
                            <p style="
                                    margin-top: 16px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #989898;
                                    line-height: 20px;
                            ">{{ (record.watched ? record.watched : '0') + ' 浏览' }}</p>
                        </div>
                    </div>
                    <div style="text-align: center; margin: 40px auto 10px auto;">
                        <a-pagination v-model:current="articles.college.news.current"
                            v-model:page-size="articles.college.news.size" :total="articles.college.news.total"
                            :showSizeChanger="false" :show-total="total => `总共 ${total} 条`" @change="togglePage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, getCurrentInstance, computed, watch } from 'vue'
    import { Space as ASpace, Pagination as APagination } from 'ant-design-vue'
    import configs from '@/assets/js/collegeConfigs'
    const { proxy } = getCurrentInstance()
    const backgroundImage = ref({
        bg1: computed(() => {
            return checkedConfig.value.image ? require(`@/assets/slices/${checkedConfig.value.image}_1@2x.png`) : ''
        }),
        bg2: computed(() => {
            return checkedConfig.value.image ? require(`@/assets/slices/${checkedConfig.value.image}_2@2x.png`) : ''
        }),
        bg3: computed(() => {
            return checkedConfig.value.image ? require(`@/assets/slices/${checkedConfig.value.image}_3@2x.png`) : ''
        }),
        left1: computed(() => {
            return checkedConfig.value.image ? require(`@/assets/slices/common_icon_arrow1_left_${checkedConfig.value.image}@2x.png`) : ''
        }),
        left2: computed(() => {
            return checkedConfig.value.image ? require(`@/assets/slices/common_icon_arrow1_left_${checkedConfig.value.image}_s@2x.png`) : ''
        }),
        right1: computed(() => {
            return checkedConfig.value.image ? require(`@/assets/slices/common_icon_arrow1_right_${checkedConfig.value.image}@2x.png`) : ''
        }),
        right2: computed(() => {
            return checkedConfig.value.image ? require(`@/assets/slices/common_icon_arrow1_right_${checkedConfig.value.image}_s@2x.png`) : ''
        }),
    })
    const majorArticleItems = {
        majorDirection: {
            remark: '专业方向'
        },
        majorIntro: {
            remark: '专业简介'
        },
        majorCourse: {
            remark: '主干课程'
        },
        majorEmployment: {
            remark: '就业前景'
        }
    }
    const checkedConfig = ref({})
    // Data
    const articles = ref({
        college: {
            intro: {},
            news: {
                current: 1,
                size: 8,
                total: 0,
            },
            majors: [],
        }
    })
    const viewCtl = ref({
        checked: 0,
        hover: {
            left: false,
            right: false,
        },
    })
    const queryMajor = ref('')
    const routePath = ref('')
    // Methods
    const getCollegeArticle = () => {
        proxy.$axios.get('/common-system-article/page/pub', { params: { serverType: `组织机构/教学单位/${checkedConfig.value.name}/学院简介`, published: true } })
            .then(res => {
                articles.value.college.intro = res.data.records[0]
            })
    }
    const getCollegeNews = () => {
        proxy.$axios.get('/common-system-article/page/pub', { params: { ...articles.value.college.news, serverType: `组织机构/教学单位/${checkedConfig.value.name}/学院新闻`, records: null, published: true } })
            .then(res => {
                articles.value.college.news = res.data
            })
    }
    const getMajorArticles = () => {
        articles.value.college.majors.forEach(major => {
            major.article = {}
            for (let item in majorArticleItems) {
                proxy.$axios.get('/common-system-article/page/pub', { params: { serverType: `组织机构/教学单位/${checkedConfig.value.name}/${major.label}/${majorArticleItems[item].remark}`, published: true } })
                    .then(res => {
                        major.article[item] = res.data.records[0]
                    })
            }
        })
    }
    const getMajors = () => {
        proxy.$axios.get(`/common-system-article-type/children?name=${encodeURIComponent('组织机构/教学单位/' + checkedConfig.value.name)}`)
            .then(res => {
                articles.value.college.majors = res.data.filter(item => item.label.indexOf('专业') >= 0)
                getCollegeArticle()
                getMajorArticles()
                getCollegeNews()
                viewCtl.value.checked = 0
                if (queryMajor.value) {
                    articles.value.college.majors.forEach((item, index) => {
                        if (item.label === queryMajor.value) {
                            viewCtl.value.checked = index
                        }
                    })
                }
            })
    }
    const toggleTable = (direction) => {
        if ('left' === direction) {
            if (viewCtl.value.checked === 0) {
                viewCtl.value.checked = articles.value.college.majors.length - 1
            } else {
                viewCtl.value.checked--
            }
        } else if ('right' === direction) {
            if ((articles.value.college.majors.length - 1) === viewCtl.value.checked) {
                viewCtl.value.checked = 0
            } else {
                viewCtl.value.checked++
            }
        }
    }
    const mouseIn = (direction) => {
        if ('left' === direction) {
            viewCtl.value.hover.left = true
        } else if ('right' === direction) {
            viewCtl.value.hover.right = true
        }
    }
    const mouseOut = (direction) => {
        if ('left' === direction) {
            viewCtl.value.hover.left = false
        } else if ('right' === direction) {
            viewCtl.value.hover.right = false
        }
    }
    const toggleIndex = (index) => {
        viewCtl.value.checked = index
    }
    const gotoArticle = (id) => {
        if (!id) {
            return
        }
        proxy.$router.push({ path: '/article', query: { id } })
    }
    const togglePage = (current) => {
        articles.value.college.news.current = current
        getCollegeNews()
    }

    onMounted(() => {
        checkedConfig.value = configs[proxy.$route.query.college] ?? {}
        queryMajor.value = proxy.$route.query.major
        if (checkedConfig.value) {
            getMajors()
        }
        routePath.value = proxy.$route.path
    })
    // eslint-disable-next-line
    let queryWatcher = watch(() => proxy.$route.query, (newValue, oldValue) => {
        checkedConfig.value = configs[newValue.college] ?? {}
        queryMajor.value = newValue.major
        if (checkedConfig.value) {
            getMajors()
        }
    }, { immediate: false })
    // eslint-disable-next-line
    let routeWatcher = watch(() => proxy.$route.path, (newValue, oldValue) => {
        queryWatcher()
        routeWatcher()
    })
    // eslint-disable-next-line
    watch(() => viewCtl.value.checked, (newValue, _) => {
        setTimeout(() => {
            let content = document.querySelector('#major-content')
            let eleIndex = document.querySelector('#major-' + newValue)
            content.scrollTo({
                left: eleIndex.offsetLeft - content.offsetLeft - content.clientWidth / 2 + eleIndex.clientWidth / 2,
                behavior: 'smooth'
            })
        }, 200)
    })
</script>

<style scoped>
    .nav p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    #major-content::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    #major-content::-webkit-scrollbar-track {
        background: #CCC;
        border-radius: 2px;
    }

    #major-content::-webkit-scrollbar-thumb {
        background: #40a0ff49;
        border-radius: 2px;
    }

    #major-content::-webkit-scrollbar-thumb:hover {
        background: #40a0ff;
    }
</style>