<template>
    <div>
        <MainPic :type="'组织机构/教学单位/宣传图'" />
        <CollegeTx5gxy />
    </div>
</template>

<script setup>
import { getCurrentInstance, ref, watch } from 'vue'
import CollegeTx5gxy from './items/CollegeTx5gxy.vue'
import MainPic from '@/components/MainPic.vue'
const { proxy } = getCurrentInstance()
const college = ref('')
// eslint-disable-next-line
watch(() => proxy.$route.query, (newValue, oldValue) => {
    college.value = newValue.college ?? ''
}, { immediate: true })
</script>

<style scoped>

</style>