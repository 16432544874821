<template>
    <div>
        <Header v-show="viewCtl.header" />
        <Banner v-show="viewCtl.banner" />
        <Body />
        <Footer v-show="viewCtl.footer" />
        <Enrollment v-show="viewCtl.enrollment" />
    </div>
</template>

<script>
import Header from './components/MainHeader.vue'
import Banner from './components/MainBanner.vue'
import Body from './components/MainBody.vue'
import Footer from './components/MainFooter.vue'
import Enrollment from './components/MainEnrollment.vue'

export default {
    name: 'App',
    components: {
        Header,
        Banner,
        Body,
        Footer,
        Enrollment,
    },
    data() {
        return {
            viewCtl: {
                header: false,
                banner: false,
                footer: false,
                enrollment: false,
            },
        }
    },
    watch: {
		// eslint-disable-next-line
		$route(to, _) {
            if (!(to.path === '/override' && typeof to.query.tag !== 'undefined')) {
                window.scrollTo(0, 0)
            }

            let header = to.matched[0].props.default.header
			this.viewCtl.header = (typeof header !== 'undefined' && header) ? true : false

            let banner = to.matched[0].props.default.banner
			this.viewCtl.banner = (typeof banner !== 'undefined' && banner) ? true : false

            let footer = to.matched[0].props.default.footer
			this.viewCtl.footer = (typeof footer !== 'undefined' && footer) ? true : false

            let enrollment = to.matched[0].props.default.enrollment
			this.viewCtl.enrollment = (typeof enrollment !== 'undefined' && enrollment) ? true : false
		},
	},
}
</script>
