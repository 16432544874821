<template>
    <div>
        <MainPic :type="'学校概况/学校领导/宣传图'" />
        <div style="display: flex; justify-content: center; align-items: center; background: #F6F8F7;">
            <div style="width: 100%; max-width: 1440px; padding: 0 100px;">
                <!-- HEADER -->
                <div style="display: flex; justify-content: space-between; align-items: center; height: 128px; background-color: #FFF; border-radius: 4px; margin-top: -60px; padding: 24px 40px;">
                    <div>
                        <div style="display: flex;">
                            <p @click="gotoPath('/override')" style="
                                    cursor: pointer;
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #282A29;
                                    line-height: 24px;
                            ">学校概况</p>
                            <p>&nbsp;&gt;&nbsp;</p>
                            <p style="
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #028848;
                                    line-height: 24px;
                            ">学校领导</p>
                        </div>
                        <p style="
                                margin-top: 24px;
                                font-size: 26px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 32px;
                        ">学校领导</p>
                    </div>
                    <img src="@/assets/slices/school_leader_img_2@2x.png" alt="" style="width: 88px; height: 88px;">
                </div>
            </div>
        </div>
        <!-- CONTENT -->
        <div v-for="(record, index) in page.records" :key="index">
            <div style="display: flex; justify-content: center; align-items: center; background: #F6F8F7; height: 126px;">
            </div>
            <div style="display: flex; justify-content: center; align-items: center; background: #FFFFFF; height: 310px;">
            </div>
            <div style="display: flex; justify-content: center; align-items: center; margin-top: -406px; padding-bottom: 46px;">
                <div style="width: 100%; max-width: 1440px; padding: 0 100px; display: flex;">
                    <div style="width: 280%; height: 360px;">
                        <img :src="record.coverUrl" alt="" style="width: 100%; height: 100%; object-fit: cover">
                    </div>
                    <div style="width: 32%;"></div>
                    <div style="width: 928%;">
                        <div style="
                                margin-top: 16px;
                                font-size: 24px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 30px;
                        ">{{ record.title }}</div>
                        <p style="
                                height: 22px;
                                margin-top: 12px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 22px;
                        ">{{ record.post }}</p>
                        <p class="lines-words" style="
                                height: 66px;
                                margin-top: 40px;
                                -webkit-line-clamp: 3;
                                font-size: 14px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 22px;
                        ">{{ record.intro }}</p>
                        <div @click="gotoArticle(record.id)" class="more-button" style="
                                margin-top: 120px;
                                width: 162px;
                                height: 50px;
                                border-radius: 4px;
                                border: 1px solid #DEDEDE;
                                cursor: pointer;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                        ">
                            <div style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 22px;
                            ">查看详情</div>
                            <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                                style="width: 16px; height: 10px; margin-left: 10px;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align: center; background-color: #F6F8F7; padding: 40px 0;">
            <a-pagination v-model:current="page.current" v-model:page-size="page.size" :total="page.total" :showSizeChanger="false"
                :show-total="total => `总共 ${total} 条`" @change="pageChange" />
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance, ref, onMounted } from 'vue'
import MainPic from '@/components/MainPic.vue'
import { Pagination as APagination } from 'ant-design-vue'
const { proxy } = getCurrentInstance()
const page = ref({
    current: 1,
    size: 8,
    total: 0,
    serverType: '学校概况/学校领导/文章列表',
    published: true,
})
const getNews = () => {
    proxy.$axios.get('/common-system-article/page/pub', { 
        params: { ...page.value, records: null }
    }).then(res => {
        page.value = res.data
    })
}
const gotoArticle = (id) => {
    if (id) {
        proxy.$router.push({ path: '/article', query: { id } })
    }
}
// eslint-disable-next-line
const pageChange = (current, size) => {
    getNews()
}
const gotoPath = (path) => {
    proxy.$router.push({ path })
}
onMounted(() => {
    getNews()
})
</script>

<style scoped>

</style>