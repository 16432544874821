<template>
    <div>
        <MainPic :type="'学校概况/宣传图'" @onload="computeTag" />
        <!-- 学校简介 -->
        <div style="display: flex; justify-content: center; align-items: center; background-color: #FFF;">
            <div style="width: 100%; max-width: 1440px; margin-top: 50px; padding: 0 100px 50px 100px;">
                <div style="display: flex;">
                    <div style="width: 4px; height: 68px; background: #028848;"></div>
                    <div style="margin-left: 10px;">
                        <p style="
                                font-size: 20px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 24px;
                        ">PROFILE</p>
                        <p style="
                                margin-top: 6px;
                                font-size: 32px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 40px;
                        ">学校简介</p>
                    </div>
                </div>
                <div style="margin-top: 60px; display: flex;">
                    <div style="width: 547%;">
                        <p style="
                                font-size: 40px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 40px;
                        ">{{ introPage.records[0] ? introPage.records[0].title : '' }}</p>
                        <p class="lines-words" style="
                                margin-top: 50px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 25px;
                                text-indent: 32px;
                                -webkit-line-clamp: 8;
                        ">{{ introPage.records[0] ? introPage.records[0].intro : '' }}</p>
                        <div @click="gotoArticle(introPage.records[0] ? introPage.records[0].id : '')" 
                            class="more-button" 
                            style="
                                    width: 162px;
                                    height: 50px;
                                    border-radius: 4px;
                                    border: 1px solid #DEDEDE;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-top: 70px;
                        ">
                            <div style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 22px;
                            ">查看详情</div>
                            <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                                style="width: 16px; height: 10px; margin-left: 10px;">
                        </div>  
                    </div>
                    <div style="width: 60%;"></div>
                    <div style="width: 632%;">
                        <video preload loop controls style="width: 100%; height: 400px; background: #000;">
                            <source v-if="introPage.records[0]" :src="introPage.records[0] ? introPage.records[0].videoUrl : ''" type="video/mp4">
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <!-- 学校领导 -->
        <div style="display: flex; justify-content: center; align-items: center; background: #F6F8F7; height: 690px;">
            <div style="width: 100%; max-width: 1440px; padding: 70px 100px;">
                <!-- 标题 -->
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div style="display: flex; align-self: center; justify-content: center;">
                        <div style="width: 4px; height: 68px; background: #028848;"></div>
                        <div style="margin-left: 10px;">
                            <p style="
                                    font-size: 20px; 
                                    font-weight: 400; 
                                    color: #282A29; 
                                    line-height: 24px;
                            ">LEADERS</p>
                            <p style="
                                    font-size: 32px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 40px;
                                    margin-top: 6px;
                            ">学校领导</p>
                        </div>
                    </div>
                    <div @click="gotoPath('/override/leader')" class="more-button" style="
                            width: 162px;
                            height: 50px;
                            border-radius: 4px;
                            border: 1px solid #DEDEDE;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                    ">
                        <p style="
                                font-size: 18px;
                                font-weight: 600;
                                line-height: 22px;
                        ">查看更多</p>
                        <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                            style="width: 16px; height: 10px; margin-left: 10px;">
                    </div>
                </div>
                <!-- 领导列表 -->
                <div style="margin-top: 50px; overflow-x: auto;">
                    <div style="display: flex; justify-content: space-between;">
                        <div v-for="(record, index) in leaderPage.records" :key="index" 
                            @click="gotoArticle(record.id)"
                            style="width: 280px; flex-shrink: 0; padding: 0 12px;">
                            <img :src="record.coverUrl" alt="" style="width: 100%; height: 360px; object-fit: cover;">
                            <div style=" text-align: center; padding: 0 12px;">
                                <p style="
                                        margin-top: 12px;
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: #282A29;
                                        line-height: 24px;
                                ">{{ record.title }}</p>
                                <p class="lines-words" style="
                                        margin-top: 12px;
                                        -webkit-line-clamp: 1;
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: #282A29;
                                        line-height: 22px;
                                ">{{ record.post }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 组织架构 -->
        <div id="override-org" style="display: flex; justify-content: center; align-items: center; background: #E4F4EC;">
            <div style="width: 100%; max-width: 1440px; padding: 60px 100px;">
                <!-- 小标题 -->
                <div style="text-align: center;">
                    <p style="
                            font-size: 20px;
                            font-weight: 400;
                            color: #282A29;
                            line-height: 24px;
                    ">ORGANIZATION</p>
                    <p style="
                            margin-top: 6px;
                            font-size: 32px;
                            font-weight: 600;
                            color: #282A29;
                            line-height: 40px;
                        ">组织架构</p>
                    <div style="display: inline-block; width: 68px; height: 4px; background: #028848; margin-top: 10px;"></div>
                </div>
                <!-- 组织 -->
                <div style="background: #FFF; margin-top: 20px;">
                    <OverrideDeptTree :depts="depts" />
                </div>
            </div>
        </div>
        <!-- 领导关怀 -->
        <div style="display: flex; justify-content: center; align-items: center; background: #FFF;">
            <div style="width: 100%; max-width: 1440px; padding: 60px 100px;">
                <!-- 标题 -->
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div style="display: flex; align-self: center; justify-content: center;">
                        <div style="width: 4px; height: 68px; background: #028848;"></div>
                        <div style="margin-left: 10px;">
                            <p style="
                                    font-size: 20px; 
                                    font-weight: 400; 
                                    color: #282A29; 
                                    line-height: 24px;
                            ">SOLICITUDE</p>
                            <p style="
                                    font-size: 32px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 40px;
                                    margin-top: 6px;
                            ">领导关怀</p>
                        </div>
                    </div>
                    <div @click="gotoPath('/override/solicitude')" class="more-button" style="
                            width: 162px;
                            height: 50px;
                            border-radius: 4px;
                            border: 1px solid #DEDEDE;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                    ">
                        <p style="
                                font-size: 18px;
                                font-weight: 600;
                                line-height: 22px;
                        ">查看更多</p>
                        <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                            style="width: 16px; height: 10px; margin-left: 10px;">
                    </div>
                </div>
                <!-- 内容 -->
                <div style="display: flex; justify-content: space-between;">
                    <div style="width: 526%;">
                        <div v-for="(record, index) in carePage.records" :key="index">
                            <div v-if="index % 2 === 0">
                                <div @click="gotoArticle(record.id)" class="hover-item" style="display: flex; margin-top: 24px; cursor: pointer;">
                                    <div v-if="record.coverUrl" style="height: 110px; width: 164%; overflow: hidden;">
                                        <img class="hover-plus" :src="record.coverUrl" alt="" style="height: 100%; width: 100%; object-fit: cover;">
                                    </div>
                                    <div style="width: 12%;"></div>
                                    <div style="width: 350%; margin-left: 12px;">
                                        <p class="hover-color lines-words" style="
                                                font-size: 18px;
                                                font-weight: 600;
                                                line-height: 24px;
                                                -webkit-line-clamp: 1;
                                        ">{{ record.title }}</p>
                                        <p class="lines-words" style="
                                                margin-top: 20px;
                                                -webkit-line-clamp: 3;
                                                word-break: break-all;
                                        ">{{ record.intro }}</p>
                                    </div>
                                </div>
                                <div v-if="index !== carePage.records.length - 1 && index !== carePage.records.length - 2" style="
                                        height: 1px;
                                        border-bottom: 1px dashed #E2E4E3;
                                        margin-top: 24px;
                                "></div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 148%;"></div>
                    <div style="width: 526%;">
                        <div v-for="(record, index) in carePage.records" :key="index">
                            <div v-if="index % 2 === 1">
                                <div @click="gotoArticle(record.id)" class="hover-item" style="display: flex; margin-top: 24px; cursor: pointer;">
                                    <div v-if="record.coverUrl" style="height: 110px; width: 164%; overflow: hidden;">
                                        <img class="hover-plus" :src="record.coverUrl" alt="" style="height: 100%; width: 100%; object-fit: cover;">
                                    </div>
                                    <div style="width: 12%;"></div>
                                    <div style="width: 350%; margin-left: 12px;">
                                        <p class="hover-color lines-words" style="
                                                font-size: 18px;
                                                font-weight: 600;
                                                line-height: 24px;
                                                -webkit-line-clamp: 1;
                                        ">{{ record.title }}</p>
                                        <p class="lines-words" style="
                                                margin-top: 20px;
                                                -webkit-line-clamp: 3;
                                                word-break: break-all;
                                        ">{{ record.intro }}</p>
                                    </div>
                                </div>
                                <div v-if="index !== carePage.records.length - 1 && index !== carePage.records.length - 2" style="
                                        height: 1px;
                                        border-bottom: 1px dashed #E2E4E3;
                                        margin-top: 24px;
                                "></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 教育理念 -->
        <div>
            <img src="@/assets/slices/profile_img_2@2x.png" alt="" style="width: 100%; height: 290px; object-fit: cover;">
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 290px; margin-top: -290px;">
                <p style="
                        font-size: 20px;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 24px;
                ">PHILOSOPHY</p>
                <p style="
                        margin-top: 6px;
                        font-size: 32px;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 40px;
                ">教育理念</p>
                <div style="width: 68px; height: 4px; background-color: #FFC314; margin-top: 10px;"></div>
                <p style="
                        margin-top: 40px;
                        font-size: 58px;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 76px;
                        letter-spacing: 10px;
                ">明德弘毅 笃学敏行</p>
            </div>
        </div>
        <!-- 学校章程 -->
        <div style="display: flex; justify-content: center; align-items: center; background: #F6F8F7;">
            <div style="width: 100%; max-width: 1440px; padding: 60px 100px;">
                <div style="display: flex;">
                    <div style="width: 600%;"></div>
                    <div style="display: flex; justify-content: flex-end; width: 640%; height: 444px;">
                        <img v-if="constitutionPage.records[0] && constitutionPage.records[0].coverUrl" :src="constitutionPage.records[0].coverUrl" alt="" 
                            style="width: 100%; height: 100%; object-fit: cover;">
                    </div>
                </div>
                <div style="display: flex; margin-top: -420px;">
                    <div style="width: 674%; padding: 32px; background-color: #FFF;">
                        <!-- 小标题 -->
                        <div style="display: flex;">
                            <div style="width: 4px; height: 68px; background: #028848;"></div>
                            <div style="margin-left: 10px;">
                                <p style="
                                        font-size: 20px;
                                        font-weight: 400;
                                        color: #282A29;
                                        line-height: 24px;
                                ">PROSPECTUS</p>
                                <p style="
                                        margin-top: 6px;
                                        font-size: 32px;
                                        font-weight: 600;
                                        color: #282A29;
                                        line-height: 40px;
                                ">学院章程</p>
                            </div>
                        </div>
                        <p class="lines-words" style="
                                height: 145px;
                                -webkit-line-clamp: 5;
                                text-indent: 32px;
                                margin-top: 32px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 25px;
                        ">{{ constitutionPage.records[0] ? constitutionPage.records[0].intro : '' }}</p>
                        <div @click="gotoArticle(constitutionPage.records[0] ? constitutionPage.records[0].id : '')" class="more-button" style="
                                margin-top: 32px;
                                width: 162px;
                                height: 50px;
                                border-radius: 4px;
                                border: 1px solid #DEDEDE;
                                cursor: pointer;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                        ">
                            <p style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 22px;
                            ">查看详情</p>
                            <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                                style="width: 16px; height: 10px; margin-left: 10px;">
                        </div>
                    </div>
                    <div style="width: 566%;"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch } from 'vue'
import OverrideDeptTree from './OverrideDeptTree.vue'
import MainPic from '@/components/MainPic.vue'
const depts = ref([])
const { proxy } = getCurrentInstance()
const introPage = ref({
    current: 1,
    size: 1,
    published: true,
    serverType: '学校概况/学校简介',
    records: [],
})
const leaderPage = ref({
    current: 1,
    size: 4,
    published: true,
    serverType: '学校概况/学校领导/文章列表',
    records: [],
})
const carePage = ref({
    current: 1,
    size: 6,
    published: true,
    serverType: '学校概况/领导关怀/文章列表',
    records: [],
})
const constitutionPage = ref({
    current: 1,
    size: 1,
    published: true,
    serverType: '学校概况/学院章程',
    records: [],
})
const getIntro = () => {
    proxy.$axios.get('/common-system-article/page/pub', {
        params: introPage.value,
    }).then(res => {
        introPage.value = res.data
    })
}
const gotoArticle = (id) => {
    if (id) {
        proxy.$router.push({ path: '/article', query: { id } })
    }
}
const computeTag = () => {
    if (proxy.$route.query && proxy.$route.query.tag) {
        if (proxy.$route.query.tag === 'org') {
            document.getElementById('override-org').scrollIntoView({ behavior: 'smooth' })
        }
    }
}
const gotoPath = (path) => {
    proxy.$router.push({ path })
}
const getLeaders = () => {
    proxy.$axios.get('/common-system-article/page/pub', {
        params: leaderPage.value,
    }).then(res => {
        leaderPage.value = res.data
    })
}
const getCares = () => {
    proxy.$axios.get('/common-system-article/page/pub', {
        params: carePage.value,
    }).then(res => {
        carePage.value = res.data
    })
}
const getConstitution = () => {
    proxy.$axios.get('/common-system-article/page/pub', {
        params: constitutionPage.value,
    }).then(res => {
        constitutionPage.value = res.data
    })
}
const getDeptTreeMap = () => {
    proxy.$axios.get('/emp-user-dept/tree-map-name/pub', {
        params: { name: '德阳农业科技职业学院' }
    }).then(res => {
        depts.value = [res.data]
    })
}
// eslint-disable-next-line
watch(() => proxy.$route.query, (newValue, oldValue) => {
    if (newValue.tag && newValue.tag === 'org') {
        document.getElementById('override-org').scrollIntoView({ behavior: 'smooth' })
    } 
}, { immediate: false })
onMounted(() => {
    getIntro()
    getLeaders()
    getCares()
    getConstitution()
    getDeptTreeMap()
})
</script>

<style scoped></style>