<template>
    <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
        <template #extra>
            <a-button type="primary" @click="goHome">Back Home</a-button>
        </template>
    </a-result>
</template>

<script setup>
import { getCurrentInstance } from 'vue';
import { Result as AResult, Button as AButton } from 'ant-design-vue'
const { proxy } = getCurrentInstance()
const goHome = () => {
    proxy.$router.push({ path: '/' })
}
</script>