<template>
    <div style="min-height: calc(100vh);width: 100%; display: flex; justify-content: center; align-items: center;">
        <div style="width: 100%;">
            <a-carousel arrows autoplay dotsClass="my-dots" dot-position="bottom" effect="fade">
                <template #prevArrow>
                    <img src="@/assets/slices/common_icon_arrow1_left_n@2x.png" class="custom-slick-arrow"
                        style="left: 60px; z-index: 1" />
                </template>
                <template #nextArrow>
                    <img src="@/assets/slices/common_icon_arrow1_right_n@2x.png" class="custom-slick-arrow"
                        style="right: 60px; z-index: 1" />1111
                </template>
                <div>
                    <video preload="auto" poster="@/assets/slices/department_tongxun_2@2x.png" style="min-width: 100%;" src="video/school.mp4" autoplay loop muted
                        playsinline uk-cover class="uk-cover"></video>
                </div>
                <div v-for="(item, index) in banners.records" :key="index" style="   min-height: calc(100vh);">
                    <img :src="item.coverUrl">
                </div>
            </a-carousel>
            <div class="dotnav">
                <arrow-down-outlined class="red" :style="{ fontSize: '20px' }" />
            </div>
            <div class="nk-contactBox">
                <div>
                    <p><strong>QQ咨询：</strong><br>80097215</p>
                    <p><strong>招生电话：</strong><br>028-<br>81275176</p>
                    <p><strong>咨询电话：</strong><br>0838-<br>6532666<br>6532669</p>
                </div>
                <div>
                    <strong>微信公众号</strong>
                    <img src="@/assets/slices/bottom_code@2x.png" alt="" style="height: 88px; width: 88px;">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue'
import { Carousel as ACarousel } from 'ant-design-vue'
import {
    ArrowDownOutlined
} from '@ant-design/icons-vue';

const { proxy } = getCurrentInstance()
const banners = ref({
    current: 1,
    size: 10,
    published: true,
    records: [],
    serverType: 'BANNER/PC端',
})
const initData = () => {
    proxy.$axios.get('/common-system-article/page/pub', {
        params: banners.value,
    }).then(res => {
        console.log(res.data)
        banners.value = res.data
    })
}
onMounted(() => {
    initData()
})
</script>

<style scoped>
.ant-carousel :deep(.slick-slide) {
    min-height: calc(100vh);
}

.ant-carousel :deep(.slick-slide img) {
    display: block;
    margin: auto;
    width: 100%;
    min-height: calc(100vh);
    object-fit: cover;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    width: 56px;
    height: 56px;
    opacity: 0.3;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
    display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
}

.ant-carousel :deep(.uk-cover) {
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ant-carousel :deep(.my-dots button) {
    height: 5px;
}

.dotnav {
    position: absolute;
    left: 53%;
    bottom: 25px;
    animation: btn 1s ease infinite alternate
}

:deep(.red path) {
    color: #fff;
}

@keyframes btn {
    from {
        transform: translateY(0)
    }

    to {
        transform: translateY(15px)
    }
}

.nk-contactBox {
    z-index: 999;
    position: fixed;
    right: 0;
    bottom: 180px;
    width: 120px;
    box-sizing: border-box;
    background: rgba(243, 243, 243, 1);
    border: 6px solid rgb(2, 136, 72);
    border-radius: 4px;
    /* border-top-left-radius: 8px; */
    padding: 15px 10px 5px;
    line-height: 20px;
    font-size: 14px;
}

.nk-contactBox p {
    margin: 0;
}

.nk-contactBox div {
    margin-bottom: 10px;
}

.nk-contactBox strong {
    color: rgb(2, 136, 72);
}
</style>