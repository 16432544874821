<template>
    <div>
        <MainPic :type="'组织机构/教学单位/宣传图'" />
        <div style="display: flex; justify-content: center; align-items: center; background: #FFF;">
            <div style="width: 100%; max-width: 1440px; padding: 0 100px;">
                <!-- 小标题 -->
                <div style="display: flex; margin-top: 50px;">
                    <div style="width: 4px; height: 68px; background: #028848;"></div>
                    <div style="margin-left: 10px;">
                        <p style="
                                font-size: 20px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 24px;
                        ">SCHOOLS & DEPARTMENTS</p>
                        <p style="
                                margin-top: 6px;
                                font-size: 32px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 40px;
                        ">教学单位</p>
                    </div>
                </div>
                <div style="margin-top: 20px; margin-bottom: 40px; column-count: 2; column-gap: 20px;">
                    <!-- LEFT -->
                    <div v-for="(college, index) in colleges" :key="index" style="page-break-inside: avoid;">
                        <div style="
                                box-shadow: 0px 2px 16px 0px rgba(0,0,0,0.16);
                                border-radius: 4px;
                                margin-bottom: 20px;
                        ">
                            <img :src="require(`@/assets/slices/img_home_${configs[college.label]?.image}.jpg`)" alt="" style="width: 100%; height: 200px; object-fit: cover;">
                            <div style="padding: 20px 40px;">
                                <p @click="gotoCollege(college.label, '')" style="
                                            font-size: 24px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 32px;
                                            cursor: pointer;
                                ">{{ college.label }}</p>
                                <div :style="{ backgroundColor: configs[college.label]?.color }" style="width: 40px; height: 3px; margin-top: 24px;">
                                </div>
                                <div v-for="(major, jndex) in college.majors" :key="jndex"
                                    @click.stop="gotoCollege(college.label, major.label)" class="hover-item"
                                    :style="{ '--hover-color': configs[college.label]?.color }"
                                    style="display: flex; justify-content: space-between; align-items: center; margin-top: 24px; cursor: pointer;">
                                    <p style="
                                            font-size: 16px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 24px;
                                    ">{{ major.label }}</p>
                                    <div class="hover-right" style="display: flex; align-items: center;">
                                        <div class="hover-color-college" style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                line-height: 20px; 
                                                color: rgb(2, 136, 72);
                                        ">查看更多</div>
                                        <img :src="require(`@/assets/slices/common_icon_arrow3_go_${configs[college.label]?.image}@2x.png`)"
                                            alt=""
                                            style="width: 16px; height: 10px; object-fit: contain; margin-left: 8px;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="page-break-inside: avoid;">
                        <div style="
                                box-shadow: 0px 2px 16px 0px rgba(0,0,0,0.16);
                                border-radius: 4px;
                                margin-bottom: 20px;
                        ">
                            <div style="padding: 20px 40px;">
                                <p @click="gotoPub" style="
                                            font-size: 24px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 32px;
                                            cursor: pointer;
                                ">{{ '公共教学部' }}</p>
                            </div>
                        </div>
                    </div>
                    <div style="page-break-inside: avoid;">
                        <div style="
                                box-shadow: 0px 2px 16px 0px rgba(0,0,0,0.16);
                                border-radius: 4px;
                                margin-bottom: 20px;
                        ">
                            <div style="padding: 20px 40px;">
                                <p @click="gotoInternational" style="
                                            font-size: 24px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 32px;
                                            cursor: pointer;
                                ">{{ '国际学院' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { getCurrentInstance, onMounted, ref } from 'vue'
    import MainPic from '@/components/MainPic.vue'
    import configs from '@/assets/js/collegeConfigs'
    const { proxy } = getCurrentInstance()
    const gotoCollege = (college, major) => {
        proxy.$router.push({ name: 'College', query: { college, major } })
    }
    const gotoPub = () => {
        proxy.$router.push({ name: 'CollegePub' })
    }
    const gotoInternational = () => {
        proxy.$router.push({ name: 'International' })
    }
    const colleges = ref([])
    onMounted(() => {
        // 获取学院
        proxy.$axios.get(`/common-system-article-type/children?name=${encodeURIComponent('组织机构/教学单位')}`).then(res1 => {
            colleges.value = res1.data.filter(item => item.label.indexOf('宣传图') && item.label.indexOf('公共教学部') < 0)
            colleges.value.forEach(college => {
                proxy.$axios.get(`/common-system-article-type/children?name=${encodeURIComponent(college.fullLabel)}`).then(res2 => {
                    college.majors = res2.data.filter(item => item.label.indexOf('学院新闻') && item.label.indexOf('学院简介') < 0)
                })
                proxy.$axios.get('/common-system-article/page/pub', { params: { serverType: `组织机构/教学单位/${college.label}/学院简介`, published: true } })
                    .then(res => {
                        college.cover = res.data.records[0]?.coverUrl
                    })
            })
        })
    })
</script>

<style scoped lang="scss">
    .hover-color-college {
        color: #989898;
    }

    .hover-item:hover .hover-color-college {
        color: var(--hover-color);
    }
</style>