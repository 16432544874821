import { createRouter, createWebHistory } from 'vue-router'
import HomeIndex from '../components/home/HomeIndex.vue'
import NewsIndex from '../components/news/NewsIndex.vue'
import CollegeList from '../components/college/CollegeList.vue'
import CollegeIndex from '../components/college/CollegeIndex.vue'
import CollegePub from '../components/college/CollegePub.vue'
import InternationalIndex from '../components/international/InternationalIndex.vue'
import DiscoveryIndex from '../components/discovery/DiscoveryIndex.vue'
import EnrollmentIndex from '../components/enrollment/EnrollmentIndex.vue'
import DeptFunctional from '../components/dept/DeptFunctional.vue'
import DeptAided from '../components/dept/DeptAided.vue'
import BuildIndex from '../components/build/BuildIndex.vue'
import InfoIndex from '../components/info/InfoIndex.vue'
import CommunicationIndex from '../components/communication/CommunicationIndex.vue'
import OverrideIndex from '../components/override/OverrideIndex.vue'
import OverrideLeader from '../components/override/OverrideLeader.vue'
import OverrideSolicitude from '../components/override/OverrideSolicitude.vue'
import OverrideEnvironment from '../components/override/OverrideEnvironment.vue'
import SchoolIndex from '../components/school/SchoolIndex.vue'
import TeachingIndex from '../components/teaching/TeachingIndex.vue'
import PublicIndex from '../components/public/PublicIndex.vue'

import UtilArticleView from '../components/util/UtilArticleView.vue'
import UtilArticlePrehandler from '../components/util/UtilArticlePrehandler.vue'
import NotFound from '../components/err/NotFound.vue'

const routes = [
    {
        name: 'Home',
        path: '/',
        component: HomeIndex,
        props: { header: true, banner: true, footer: true, enrollment: false },
    },
    {
        name: 'Override',
        path: '/override',
        component: OverrideIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'OverrideLeader',
        path: '/override/leader',
        component: OverrideLeader,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'OverrideSolicitude',
        path: '/override/solicitude',
        component: OverrideSolicitude,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'OverrideEnvironment',
        path: '/override/environment',
        component: OverrideEnvironment,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'DeptFunctional',
        path: '/dept/functional',
        component: DeptFunctional,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'DeptAided',
        path: '/dept/aided',
        component: DeptAided,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'College',
        path: '/dept/college',
        component: CollegeIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'CollegeList',
        path: '/dept/college/list',
        component: CollegeList,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'CollegePub',
        path: '/dept/college/pub',
        component: CollegePub,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'International',
        path: '/dept/international',
        component: InternationalIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'News',
        path: '/news',
        component: NewsIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'Teaching',
        path: '/teaching',
        component: TeachingIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'Enrollment',
        path: '/enrollment',
        component: EnrollmentIndex,
        props: { header: true, banner: false, footer: true, enrollment: true },
    },
    {
        name: 'Discovery',
        path: '/discovery',
        component: DiscoveryIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'School',
        path: '/school',
        component: SchoolIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'Public',
        path: '/public',
        component: PublicIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'Build',
        path: '/build',
        component: BuildIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'Info',
        path: '/info',
        component: InfoIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'Communication',
        path: '/communication',
        component: CommunicationIndex,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'UtilArticleView',
        path: '/article',
        component: UtilArticleView,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        name: 'UtilArticlePrehandler',
        path: '/article/prehandler',
        component: UtilArticlePrehandler,
        props: { header: false, banner: false, footer: false, enrollment: false },
    },
    {
        name: 'NotFound',
        path: '/err/404',
        component: NotFound,
        props: { header: true, banner: false, footer: true, enrollment: false },
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/err/404',
    },
]

const router = createRouter({
    // 内部提供了 html5 路由的实现。也有 hash 模式的实现。
    history: createWebHistory('/voc'),
    routes, // `routes: routes` 的缩写
})

export { router }