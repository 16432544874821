const newWindow = (href) => {
    let a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    a.target = "_blank"
    a.href = href
    a.click()
    document.body.removeChild(a)
}

export {
    newWindow
}