export default {
    '卫生与健康学院': {
        name: '卫生与健康学院',
        image: 'department_yihu',
        color: '#08C5C4'
    },
    '教育与艺体学院': {
        name: '教育与艺体学院',
        image: 'department_yiti',
        color: '#7516C9'
    },
    '管理与城乡建设学院': {
        name: '管理与城乡建设学院',
        image: 'department_nongye',
        color: '#028848'
    },
    '信息与建造工程学院': {
        name: '信息与建造工程学院',
        image: 'department_tongxun',
        color: '#00A8DE'
    },
}