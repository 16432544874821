<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: center; background: #FFF;">
            <div style="width: 100%;">
                <img :src="backgroundImage.bg" alt=""
                    style="width: 100%; height: 620px; object-fit: cover;">
                <div
                    style="margin-top: -620px; height: 620px; width: 100%; display: flex; flex-direction: row-reverse;">
                    <div style="height: 100%; padding: 50px; background-color: #fff; opacity: 0.8;">
                        <div @click="gotoList" style="display: flex; cursor: pointer;">
                            <div style="width: 4px; height: 68px; background: #028848;"></div>
                            <div style="margin-left: 12px;">
                                <div style="
                                    width: 274px;
                                    height: 24px;
                                    font-size: 20px;
                                    font-weight: 400;
                                    color: #282A29;
                                    line-height: 24px;
                                ">SCHOOLS & DEPARTMENTS</div>
                                <div style="
                                    width: 128px;
                                    height: 40px;
                                    font-size: 32px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 40px;
                                    margin-top: 6px;
                                ">教学单位</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; width: 780px;">
                            <div v-for="(college, index1) in colleges" :key="index1" style="min-width: 320px;" @mouseenter="mouseenter(college)">
                                <div @click="gotoCollege(college.label)" style="display: flex; align-items: center; margin-top: 40px; cursor: pointer;">
                                    <div class="college-item-text">{{ college.label }}</div>
                                    <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt="" style="width: 17px; height: 10px; object-fit: cover; margin-left: 8px;">
                                </div>
                                <div style="display: flex; flex-direction: column; flex-wrap: wrap; height: 150px;">
                                    <div v-for="(major, index2) in college.majors" :key="index2" @click="gotoCollege(college.label, major.label)" class="major-item-text lines-words">{{ major.label.replace('专业', '') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { getCurrentInstance, onMounted, ref } from 'vue'
    import configs from '@/assets/js/collegeConfigs'
    const { proxy } = getCurrentInstance()
    const gotoList = () => {
        proxy.$router.push({ path: '/dept/college/list' })
    }
    const gotoCollege = (college, major) => {
        proxy.$router.push({ path: '/dept/college', query: { college, major } })
    }
    const backgroundImage = ref({
        bg: require('@/assets/slices/img_home_department_nongye.jpg')
    })
    const colleges = ref([])
    onMounted(() => {
        // 获取学院
        proxy.$axios.get(`/common-system-article-type/children?name=${encodeURIComponent('组织机构/教学单位')}`).then(res1 => {
                colleges.value = res1.data.filter(item => item.label.indexOf('宣传图') && item.label.indexOf('公共教学部') < 0)
                colleges.value.forEach(college => {
                    proxy.$axios.get(`/common-system-article-type/children?name=${encodeURIComponent(college.fullLabel)}`).then(res2 => {
                        college.majors = res2.data.filter(item => item.label.indexOf('学院新闻') && item.label.indexOf('学院简介') < 0)
                    })
                })
            })
    })
    const mouseenter = (college) => {
        backgroundImage.value.bg = require(`@/assets/slices/img_home_${configs[college.label].image}.jpg`)
    }
</script>

<style scoped>
    .major-item-text {
        margin-top: 16px;
        width: 160px;
        height: 21px;
        font-size: 14px;
        font-weight: 400;
        color: #282A29;
        line-height: 21px;
        cursor: pointer;
        -webkit-line-clamp: 1;
    }

    .college-item-text {
        height: 22px;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #282A29;
        line-height: 22px;
    }
</style>