<template>
    <div>
        <div v-show="!viewCtl.open" @click="open(true)"
            style="position: fixed; top: 240px; right: 40px; z-index: 300; cursor: pointer;">
            <div style="
                    width: 110px;
                    height: 46px;
                    background: #028848;
                    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.12);
                    border-radius: 8px 8px 0px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            ">
                <img src="@/assets/slices/编组_6@2x.png" alt="" style="width: 38px; height: 32px;">
            </div>
            <div style="
                    width: 110px;
                    height: 46px;
                    background: #FFFFFF;
                    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.16);
                    border-radius: 0px 0px 8px 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            ">
                <div style="
                        font-size: 14px;
                        font-weight: 600;
                        color: #282A29;
                        line-height: 20px;
                ">在线报名</div>
            </div>
        </div>
        <div v-show="viewCtl.open" id="openContainer" style="
                position: fixed; 
                top: 100px; 
                right: 40px; 
                width: 600px;
                height: 548px;
                background: #FFFFFF; 
                box-shadow: 0px 1px 16px 0px rgba(0,0,0,0.16); 
                border-radius: 8px; 
                z-index: 300;
                overflow: hidden;
                display: flex;
                flex-direction: row-reverse;
        ">
            <div style="width: 600px; padding: 24px; display: flex; justify-content: space-between;">
                <div style="width: 300px;">
                    <div>
                        <p style="
                                font-size: 22px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 28px;
                        ">在线报名</p>
                        <p style="
                                margin-top: 4px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #989898;
                                line-height: 20px;
                        ">ENROLLMENT ONLINE</p>
                    </div>
                    <a-form :layout="'vertical'" :model="formState" style="margin-top: 16px;">
                        <a-form-item label="姓名" name="name" :rules="[{ required: true, message: '请输入您的姓名!' }]">
                            <a-input v-model:value="formState.name" placeholder="请输入您的姓名" />
                        </a-form-item>
                        <a-form-item label="手机" name="phone" :rules="[{ required: true, message: '请输入您的手机号!' }]">
                            <a-input v-model:value="formState.phone" placeholder="请输入您的手机号" />
                        </a-form-item>
                        <a-form-item label="性别" name="gender" :rules="[{ required: true, message: '请输入您的性别!' }]">
                            <a-radio-group v-model:value="formState.gender">
                                <a-radio value="男">男</a-radio>
                                <a-radio value="女">女</a-radio>
                            </a-radio-group>
                        </a-form-item>
                        <a-form-item label="个人说明" name="remark">
                            <a-input v-model:value="formState.remark" placeholder="请输入您的个人情况" />
                        </a-form-item>
                        <a-form-item label="" name="signCode" :rules="[{ required: true, message: '请输入验证码!' }]">
                            <a-space>
                                <a-input v-model:value="formState.signCode" placeholder="请输入验证码" />
                                <img v-if="signCode.base64" @click="flushCode" :src="'data:image/jpg;base64,' + signCode.base64" alt="" style="height: 32px; cursor: pointer;">
                            </a-space>
                        </a-form-item>
                        <a-form-item style="margin-bottom: 0;">
                            <a-button @click="submit" :disabled="submitDisabled" type="primary" html-type="submit" style="background-color: #028848; width: 300px;"><a style="color: #FFF;">提交</a></a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div style="display: flex; flex-direction: column; align-items: flex-end; justify-content: space-between;">
                    <img @click="open(false)" src="@/assets/slices/application_icon_delete@2x.png" alt="" style="width: 40px; height: 40px; cursor: pointer;">
                    <img src="@/assets/slices/application_img_2@2x.jpg" alt="" style="width: 184px; height: 404px; margin-right: 8px;">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, computed } from 'vue'
import { Button as AButton, Form as AForm, Input as AInput, Radio as ARadio, Space as ASpace } from 'ant-design-vue'
const AFormItem = AForm.Item
const ARadioGroup = ARadio.Group
const { proxy } = getCurrentInstance()
const viewCtl = ref({
    open: false,
})
const formState = ref({
    name: '',
    phone: '',
    gender: '',
    remark: '',
    signCode: '',
})
const signCode = ref({})
const open = (flag) => {
    viewCtl.value.open = flag
    if (flag) {
        const container = document.getElementById('openContainer')
        container.animate({
            top: ['240px', '100px'],
            width: ['110px', '600px'],
            height: ['46px', '548px'],
        }, 200)
    }
}
const getSignCode = () => {
    proxy.$axios.get('/common-system-enrollment-online/sign-code')
        .then(res => {
            signCode.value = res.data
        })
}
const flushCode = () => {
    if (signCode.value.id) {
        proxy.$axios.get('/common-system-enrollment-online/sign-code-flush', {
            params: { id: signCode.value.id }
        })
        .then(res => {
            signCode.value = res.data
        })
    }
}
const submit = () => {
    submitDisabled.value = true
    proxy.$axios.post('/common-system-enrollment-online', {
       ...formState.value, signId: signCode.value.id 
    }).then(() => {
        submitDisabled.value = false
    }).catch(() => {
        submitDisabled.value = false
    })
}
onMounted(() => {
    getSignCode()
})
const submitDisabled = computed(() => {
    return !(formState.value.name && formState.value.gender && formState.value.phone && formState.value.signCode)
})
</script>

<style scoped></style>