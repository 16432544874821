<template>
    <div></div>
</template>

<script setup>
    import { onMounted, getCurrentInstance } from 'vue'
    const { proxy } = getCurrentInstance()
    onMounted(() => {
        if (proxy.$route.query && proxy.$route.query.type) {
            proxy.$axios.get('/common-system-article/page/pub', {
                params: {
                    current: 1,
                    size: 1,
                    serverType: proxy.$route.query.type,
                    published: true,
                }
            })
                .then(res => {
                    if (res.data.records && res.data.records[0]) {
                        proxy.$router.push({ path: '/article', query: { id: res.data.records[0].id }, replace: true })
                    } else {
                        proxy.$router.push({ name: 'NotFound', replace: true })
                    }
                })
        } else {
            proxy.$globalNotify('未查找到当前分类，请联系管理员')
            proxy.$router.back()
        }
    })
</script>

<style scoped></style>