<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: center; background: #F6F8F7;">
            <div style="width: 100%; max-width: 1440px; padding: 20px 100px 60px 100px; margin-top: 24px;">
                <!-- 头部 -->
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <!-- table -->
                    <div style="display: flex;">
                        <div @click="toggleTable(0)" :style="{ color: checked === 0 ? '#028848' : '', background: checked === 0 ? '#E4F4EC' : '' }" style="
                                width: 160px;
                                height: 62px;
                                border-radius: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 20px;
                                font-weight: 600;
                                line-height: 24px;
                                cursor: pointer;
                            ">校园风光</div>
                        <div @click="toggleTable(1)" :style="{ color: checked === 1 ? '#028848' : '', background: checked === 1 ? '#E4F4EC' : '' }" style="
                                width: 160px;
                                height: 62px;
                                border-radius: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 20px;
                                font-weight: 600;
                                line-height: 24px;
                                cursor: pointer;
                            ">教室环境</div>
                        <div @click="toggleTable(2)" :style="{ color: checked === 2 ? '#028848' : '', background: checked === 2 ? '#E4F4EC' : '' }" style="
                                width: 160px;
                                height: 62px;
                                border-radius: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 20px;
                                font-weight: 600;
                                line-height: 24px;
                                cursor: pointer;
                            ">食宿环境</div>
                    </div>
                    <!-- 小标题 -->
                    <div style="display: flex;">
                        <div style="margin-right: 10px; display: flex; flex-direction: column; align-items: flex-end;">
                            <p style="
                                    font-size: 20px;
                                    font-weight: 400;
                                    color: #282A29;
                                    line-height: 24px;
                                ">SCENERY</p>
                            <p style="
                                    margin-top: 6px;
                                    font-size: 32px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 40px;
                                    ">校园风光</p>
                        </div>
                        <div style="width: 4px; height: 68px; background: #028848;"></div>
                    </div>
                </div>

                <div style="margin-top: 40px; background-color: #FFF;">
                    <a-carousel arrows :after-change="carouselToggleTable" ref="carousel">
                        <template #prevArrow>
                            <img src="@/assets/slices/common_icon_arrow1_left_n@2x.png" class="custom-slick-arrow"
                                style="left: 24px; z-index: 1" />
                        </template>
                        <template #nextArrow>
                            <img src="@/assets/slices/common_icon_arrow1_right_n@2x.png" class="custom-slick-arrow"
                                style="right: 24px; z-index: 1" />
                        </template>
                        <div v-for="(item, index) in infos" :key="index">
                            <div>
                                <div style="display: flex;">
                                    <div v-for="(article, jndex) in item.records" :key="jndex" 
                                        :style="{ width: jndex <= 2 ? '33.33%' : '' }" 
                                        style="overflow: hidden;">
                                        <img v-if="jndex <= 2" :src="article.coverUrl" style="height: 280px;"/>
                                    </div>
                                </div>
                                <div style="display: flex;">
                                    <div v-for="(article, jndex) in item.records" :key="jndex" 
                                        :style="{ width: jndex > 2 ? '33.33%' : '' }" 
                                        style="overflow: hidden;">
                                        <img v-if="jndex > 2" :src="article.coverUrl" style="height: 280px;"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref } from 'vue';
import { Carousel as ACarousel } from 'ant-design-vue'
const { proxy } = getCurrentInstance()
const infos = ref({
    school: {
        current: 1,
        size: 6,
        published: true,
        records: [],
        serverType: '学校概况/校园风光/校园风光',
    },
    classroom: {
        current: 1,
        size: 6,
        published: true,
        records: [],
        serverType: '学校概况/校园风光/教室环境',
    },
    canteen: {
        current: 1,
        size: 6,
        published: true,
        records: [],
        serverType: '学校概况/校园风光/食宿环境',
    },
})
const checked = ref(0)
const getInfos = () => {
    for (let item in infos.value) {
        proxy.$axios.get('/common-system-article/page/pub', {
            params: { ...infos.value[item] }
        }).then(res => {
            infos.value[item] = res.data
        })
    }
}
const toggleTable = (index) => {
    checked.value = index
    proxy.$refs.carousel.goTo(index, false)
}
const carouselToggleTable = (index) => {
    checked.value = index
}

onMounted(() => {
    getInfos()
})
</script>

<style scoped>

.ant-carousel :deep(.slick-slide img) {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    width: 56px;
    height: 56px;
    opacity: 0.3;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
    display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
}

</style>