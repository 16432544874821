<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: center; background: #F6F8F7;">
            <div style="width: 100%; max-width: 1440px;">
                <div style="background: #FFF; padding: 40px; border-radius: 4px;">
                    <!-- 标题 -->
                    <div style="display: flex; justify-content: space-between;">
                        <div style="display: flex; align-self: center; justify-content: center;">
                            <div style="width: 4px; height: 68px; background: #028848;"></div>
                            <div style="margin-left: 10px;">
                                <p style="
                                        font-size: 20px; 
                                        font-weight: 400; 
                                        color: #282A29; 
                                        line-height: 24px;
                                ">NEWS</p>
                                <p style="
                                        font-size: 32px;
                                        font-weight: 600;
                                        color: #282A29;
                                        line-height: 40px;
                                        margin-top: 6px;
                                ">新闻资讯</p>
                            </div>
                        </div>
                        <div @click="goNews()" class="more-button" style="
                                width: 162px;
                                height: 50px;
                                border-radius: 4px;
                                border: 1px solid #DEDEDE;
                                cursor: pointer;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                        ">
                            <div style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 22px;
                                    color: rgb(2, 136, 72);
                            ">查看更多</div>
                            <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                                style="width: 16px; height: 10px; margin-left: 10px;">
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-between; margin-top: 40px;">
                        <!-- 影像 -->
                        <div style="width: 56.5%;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <div style="display: flex; align-items: center;">
                                    <img src="@/assets/slices/home_icon_video@2x.png" alt=""
                                        style="width: 24px; height: 24px;">
                                    <p style="
                                            margin-left: 10px; 
                                            font-size: 20px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 24px;
                                    ">学校影像</p>
                                </div>
                                <div style="display: flex; align-items: center; cursor: pointer;">
                                    <p @click="goNews('学校影像')" style="
                                       font-size: 14px;
                                    font-weight: 400;
                                    color: #028848;
                                    line-height: 20px;
                                        cursor: pointer;
                                ">更多</p>
                                    <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                                        style="width: 16px; height: 10px; margin-left: 8px;">
                                </div>

                            </div>
                            <div style="
                                    width: 100%;
                                    height: 1px;
                                    background: #028848;
                                    margin-top: 10px;
                            "></div>
                            <video preload="metadata" controls
                                style="width: 100%; height: 426px; margin-top: 20px; background: #000;">
                                <source v-if="infos.video.records[0]"
                                    :src="infos.video.records[0] ? infos.video.records[0].videoUrl : ''"
                                    type="video/mp4">
                            </video>
                        </div>
                        <!-- 新闻 -->
                        <div style="margin-left: 44px; width: 40%;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <div style="display: flex; align-items: center;">
                                    <div style="width: 24px; height: 24px;">
                                        <img src="@/assets/slices/home_icon_news@2x.png" alt=""
                                            style="width: 100%; height: 100%;">
                                    </div>
                                    <p style="
                                            margin-left: 10px; 
                                            font-size: 20px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 24px;
                                    ">学校新闻</p>
                                </div>
                                <div @click="goNews('学校新闻')" style="
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: rgb(2, 136, 72);
                                        line-height: 20px;
                                        cursor: pointer;
                                ">更多<img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                            style="width: 16px; height: 10px; margin-left: 10px;"></div>
                            </div>
                            <div style="
                                    height: 1px;
                                    background: #028848;
                                    margin-top: 10px;
                            "></div>
                            <div style="display: flex; flex-direction: column; height: 426px; margin-top: 20px;">
                                <div v-for="(item, index) in infos.news.records" :key="index">
                                    <div @click="gotoArticle(item.id)" class="hover-item"
                                        :style="{ marginBottom: index !== (infos.news.records.length - 1) ? '24px' : '' }"
                                        style="display: flex; height: 110px; cursor: pointer;">
                                        <div v-if="item.coverUrl" style="height: 100%; width: 164%; overflow: hidden;">
                                            <img class="hover-plus" :src="item.coverUrl" alt=""
                                                style="height: 100%; width: 100%; object-fit: cover;">
                                        </div>
                                        <div style="width: 12%;"></div>
                                        <div style="width: 320%;">
                                            <div class="hover-color lines-words" style="
                                                    font-size: 18px;
                                                    font-weight: 600;
                                                    line-height: 24px;
                                                    -webkit-line-clamp: 1;
                                            ">{{ item.title }}</div>
                                            <div class="lines-words" style="
                                                    margin-top: 20px;
                                                    -webkit-line-clamp: 3;
                                            ">{{ item.intro }}</div>
                                        </div>
                                    </div>
                                    <div v-if="index !== (infos.news.records.length - 1)" style="
                                            margin-bottom: 24px;
                                            height: 1px;
                                            border-bottom: 1px dashed #E2E4E3;
                                    "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-between; margin-top: 40px;">
                        <!-- 通知 -->
                        <div style="width: 40%;">
                            <!-- TOP -->
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <div style="display: flex; align-items: center;">
                                    <img src="@/assets/slices/home_icon_notice@2x.png" alt=""
                                        style="width: 24px; height: 24px;">
                                    <p style="
                                            margin-left: 10px; 
                                            font-size: 20px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 24px;
                                    ">通知公告</p>
                                </div>
                                <p @click="goNews('通知公告')" style="
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: rgb(2, 136, 72);
                                        line-height: 20px;
                                        cursor: pointer;
                                ">更多<img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                            style="width: 16px; height: 10px; margin-left: 10px;"></p>
                            </div>
                            <div style="
                                    height: 1px;
                                    background: #028848;
                                    margin-top: 10px;
                            "></div>
                            <!-- BODY -->
                            <div style="
                                    display: flex;
                                    margin-top: 20px;
                            ">
                                <div style="
                                        display: flex;
                                        flex-direction: column;
                                        padding: 5px 0;
                                ">
                                    <div v-for="index in infos.notice.records.length" :key="index" style="
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                    ">
                                        <div style="
                                                width: 10px;
                                                height: 10px;
                                                background: #282A29;
                                                border-radius: 10px;
                                        "></div>
                                        <div style="
                                                width: 1px;
                                                height: 98px;
                                                border-left: 1px dashed #E2E4E3;
                                        "></div>
                                    </div>
                                    <div style="
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                    ">
                                        <div style="
                                                width: 10px;
                                                height: 10px;
                                                background: #282A29;
                                                border-radius: 10px;
                                        "></div>
                                    </div>
                                </div>
                                <div style="
                                        margin-left: 12px;
                                        width: 100%;
                                        padding-right: 10px;
                                ">
                                    <div v-for="(item, index) in infos.notice.records" :key="index"
                                        @click="gotoArticle(item.id)" class="hover-item"
                                        style="height: 108px; cursor: pointer;">
                                        <p style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                color: #696969;
                                                line-height: 20px;
                                        ">{{ item.createTime.split(' ')[0] }}</p>
                                        <div class="hover-right lines-words" style="
                                                position: relative;
                                                margin-top: 8px;
                                                font-size: 18px;
                                                font-weight: 600;
                                                color: #282A29;
                                                line-height: 24px;
                                                -webkit-line-clamp: 1;
                                        ">{{ item.title }}</div>
                                        <div class="hover-right" style="
                                                position: relative;
                                                display: flex;
                                                align-items: center;
                                                margin-top: 8px;
                                        ">
                                            <div style="
                                                    font-size: 14px;
                                                    font-weight: 400;
                                                    color: #028848;
                                                    line-height: 20px;
                                            ">点击查看</div>
                                            <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt="" style="
                                                    height: 10px;
                                                    width: 16px;
                                                    margin-left: 8px;
                                            ">
                                        </div>
                                    </div>
                                    <p @click="goNews('通知公告')" style="
                                            font-size: 14px;
                                            font-weight: 400;
                                            color: #989898;
                                            line-height: 20px;
                                            cursor: pointer;
                                    ">查看更多公告</p>
                                </div>
                            </div>
                        </div>
                        <!-- 专题 -->
                        <div style="margin-left: 44px; width: 56.5%;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <div style="display: flex; align-items: center;">
                                    <img src="@/assets/slices/home_icon_subject@2x.png" alt=""
                                        style="width: 24px; height: 24px;">
                                    <p style="
                                            margin-left: 10px; 
                                            font-size: 20px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 24px;
                                    ">媒体聚焦</p>
                                </div>
                                <p @click="goNews('媒体聚焦')" style="
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: rgb(2, 136, 72);
                                        line-height: 20px;
                                        cursor: pointer;
                                ">更多<img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt=""
                            style="width: 16px; height: 10px; margin-left: 10px;"></p>
                            </div>
                            <div style="
                                    height: 1px;
                                    background: #028848;
                                    margin-top: 10px;
                            "></div>
                            <div v-for="(item, index) in infos.subject.records" :key="index"
                                @click="gotoArticle(item.id)" class="hover-item" style="
                                        margin-top: 20px;
                                        padding: 20px 24px;
                                        background: #F6F8F7;
                                        display: flex;
                                        cursor: pointer;
                            ">
                                <div style="width: 152%; height: 116px; overflow: hidden;">
                                    <img class="hover-plus" :src="item.coverUrl" alt="" style="
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                    " />
                                </div>
                                <div style="width: 20%;"></div>
                                <div style="width: 480%;">
                                    <div class="hover-color lines-words" style="
                                            font-size: 18px;
                                            font-weight: 600;
                                            line-height: 24px;
                                            -webkit-line-clamp: 1;
                                    ">{{ item.title }}</div>
                                    <div class="lines-words" style="
                                            margin-top: 12px;
                                            -webkit-line-clamp: 2;
                                    ">{{ item.intro }}</div>
                                    <div style="
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            margin-top: 16px;
                                    ">
                                        <div style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                color: #989898;
                                                line-height: 20px;
                                        ">{{ item.createTime.split(' ')[0] }}</div>
                                        <div style="
                                                display: flex;
                                                align-items: center;
                                                margin-top: 8px;
                                                cursor: pointer;
                                        ">
                                            <p style="
                                                    font-size: 14px;
                                                    font-weight: 400;
                                                    color: #028848;
                                                    line-height: 20px;
                                            ">点击查看</p>
                                            <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt="" style="
                                                    height: 10px;
                                                    width: 16px;
                                                    margin-left: 8px;
                                            ">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance, ref, onMounted } from 'vue'
const { proxy } = getCurrentInstance()
const goNews = tab => {
    proxy.$router.push({ name: 'News', query: { tab: tab } })
}
const infos = ref({
    video: {
        current: 1,
        size: 1,
        published: true,
        records: [],
        serverType: '新闻资讯/学校影像'
    },
    news: {
        current: 1,
        size: 3,
        published: true,
        records: [],
        serverType: '新闻资讯/学校新闻'
    },
    notice: {
        current: 1,
        size: 3,
        published: true,
        records: [],
        serverType: '新闻资讯/通知公告'
    },
    subject: {
        current: 1,
        size: 2,
        published: true,
        records: [],
        serverType: '新闻资讯/媒体聚焦'
    },
})
const getInfos = () => {
    for (let item in infos.value) {
        proxy.$axios.get('/common-system-article/page/pub', {
            params: { ...infos.value[item] }
        }).then(res => {
            infos.value[item] = res.data
        })
    }
}
const gotoArticle = (id) => {
    if (id) {
        proxy.$router.push({ path: '/article', query: { id } })
    }
}
onMounted(() => {
    getInfos()
})
</script>

<style scoped></style>