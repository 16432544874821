<template>
    <div style="width: 100%; position: relative; z-index: 1;">
        <a-config-provider :locale="locale">
            <router-view :key="route.fullPath" />
        </a-config-provider>
    </div>
</template>

<script setup>
import { getCurrentInstance } from 'vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { ConfigProvider as AConfigProvider } from 'ant-design-vue'
const { proxy } = getCurrentInstance()
const route = proxy.$route
const locale = zhCN
</script>