<!-- 多子栏目 -->
<template>
    <div>
        <MainPic :type="`${props.type}/宣传图`" />
        <div style="display: flex; justify-content: center; align-items: center; background: #F6F8F7;">
            <div style="width: 100%; max-width: 1440px; margin-top: 50px; padding: 0 100px;">
                <div style="display: flex; justify-content: space-between;">
                    <div style="display: flex;">
                        <div style="width: 4px; height: 68px; background: #028848;"></div>
                        <div style="margin-left: 10px;">
                            <p style="
                                    font-size: 20px;
                                    font-weight: 400;
                                    color: #282A29;
                                    line-height: 24px;
                            ">{{ articleType.en }}</p>
                            <p style="
                                    margin-top: 6px;
                                    font-size: 32px;
                                    font-weight: 600;
                                    color: #282A29;
                                    line-height: 40px;
                            ">{{ articleType.label }}</p>
                        </div>
                    </div>
                </div>
                <div style="display: flex; align-items: flex-start;">
                    <div style="width: 240%; margin-top: 20px;">
                        <div v-for="(record, index) in articleType.children" :key="index">
                            <div v-if="index === checked" style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 240px;
                                    height: 78px;
                                    background: #028848;
                                    border-left: 3px solid #FFC314;
                                    padding: 0 24px;
                            ">
                                <div>
                                    <p style="
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: #FFFFFF;
                                            line-height: 24px;
                                    ">{{ record.label }}</p>
                                    <p style="
                                            font-size: 14px;
                                            color: #FFFFFF;
                                            margin-top: 4px;
                                    ">{{ record.en }}</p>
                                </div>
                                <p style="
                                        font-size: 18px;
                                        color: #FFFFFF;
                                        line-height: 24px;
                                ">{{ '>' }}</p>
                            </div>
                            <div v-else @click="toggleTable(index)" style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 240px;
                                    height: 78px;
                                    background: #FFF;
                                    border-bottom: 1px solid #E2E4E3;
                                    cursor: pointer;
                                    padding: 0 24px;
                            ">
                                <div>
                                    <p style="
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: #282A29;
                                            line-height: 24px;
                                    ">{{ record.label }}</p>
                                    <p style="
                                            font-size: 14px;
                                            color: #989898;
                                            margin-top: 4px;
                                    ">{{ record.en }}</p>
                                </div>
                                <p style="
                                        font-size: 18px;
                                        color: #989898;
                                        line-height: 24px;
                                ">{{ '>' }}</p>
                            </div>
                        </div>
                    </div>
                    <div style="width: 16%;"></div>
                    <div style="width: 984%;">
                        <div v-for="(record, index) in page.records" :key="index" @click="gotoArticle(record.id)"
                            class="hover-item"
                            style="background: #FFF; padding: 24px; display: flex; justify-content: space-between; margin-top: 20px; cursor: pointer;">
                            <div style="width: 224%;">
                                <div style="width: 100%; height: 150px; overflow: hidden;">
                                    <img class="hover-plus" :src="record.coverUrl" alt=""
                                        style="width: 100%; height: 100%; object-fit: cover;">
                                </div>
                                <div
                                    style="height: 52px; width: 73px; background: #028848; opacity: 0.7; margin-top: -64px; padding: 6px 0 0 12px;">
                                    <div style="
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: #FFFFFF;
                                        line-height: 22px;
                                    ">
                                        {{ record.createTime ? (record.createTime.split(' ')[0].split('-')[1] + '/' +
                                        record.createTime.split(' ')[0].split('-')[2]) : '' }}
                                    </div>
                                    <div style="
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                        line-height: 18px;
                                    ">{{ record.createTime ? (record.createTime.split(' ')[0].split('-')[0]) : '' }}
                                    </div>
                                </div>
                            </div>
                            <div style="width: 24%;"></div>
                            <div style="width: 688%;">
                                <div class="hover-color" style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 24px;
                                ">{{ record.title }}</div>
                                <div style="height: 78px;">
                                    <div style="
                                        margin-top: 12px;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 3;
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: #282A29;
                                        line-height: 26px;
                                    ">{{ record.intro }}</div>
                                </div>
                                <p style="
                                    margin-top: 16px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #989898;
                                    line-height: 20px;
                                ">{{ record.watched + ' 浏览' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin: 40px auto 60px auto;">
                    <a-pagination v-model:current="page.current" v-model:page-size="page.size" :total="page.total"
                        :showSizeChanger="false" :show-total="total => `总共 ${total} 条`" @change="pageChange(checked)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import MainPic from '@/components/MainPic.vue'
    import {
        onMounted,
        ref,
        getCurrentInstance,
        watch,
        nextTick
    } from 'vue'
    const { proxy } = getCurrentInstance()
    import {
        Pagination as APagination
    } from 'ant-design-vue'
    const props = defineProps({
        type: {
            type: String,
            required: true
        },
    })
    const checked = ref(-1)
    const articleType = ref({
        children: []
    })
    const page = ref({})
    const toggleTable = index => {
        checked.value = index
        page.value = articleType.value.children[index].page
    }
    const pageChange = (index) => {
        proxy.$axios.get('/common-system-article/page/pub', {
            params: { ...articleType.value.children[index].page, records: null }
        }).then(res => {
            articleType.value.children[index].page = res.data
            if (index === checked.value) {
                page.value = res.data
            }
        })
    }
    onMounted(() => {
        proxy.$axios.get(`/common-system-article-type/name?name=${encodeURIComponent(props.type)}`).then(res => {
            articleType.value = res.data
            proxy.$axios.get(`/common-system-article-type/children?name=${encodeURIComponent(props.type)}`).then(res => {
                let subList = res.data.filter(item => item.label.indexOf('宣传图') < 0)
                subList.forEach(item => {
                    item.page = {
                        current: 1,
                        size: 8,
                        total: 0,
                        serverType: item.fullLabel,
                        published: true,
                    }
                })
                articleType.value.children = subList
                for (let index1 = 0; index1 < articleType.value.children.length; index1++) {
                    pageChange(index1)
                }
                nextTick(() => {
                    if (proxy.$route.query.tab) {
                        if (articleType.value.children && articleType.value.children.length > 0) {
                            articleType.value.children.forEach((item, index2) => {
                                if (item.label === proxy.$route.query.tab) {
                                    toggleTable(index2)
                                }
                            })
                        }
                    }
                    if (checked.value < 0) {
                        toggleTable(0)
                    }
                })
            })
        })
    })
    // eslint-disable-next-line
    let queryWatcher = watch(() => proxy.$route.query, (newValue, oldValue) => {
        let tab = newValue.tab ?? ''
        if (!tab) {
            toggleTable(0)
        } else {
            if (articleType.value.children && articleType.value.children.length > 0) {
                articleType.value.children.forEach((item, index2) => {
                    if (item.label === tab) {
                        toggleTable(index2)
                    }
                })
            }
        }
    }, { immediate: false })
    // eslint-disable-next-line
    // let routeWatcher = watch(() => proxy.$route.path, () => {
        // queryWatcher()
        // routeWatcher()
    // })
    const gotoArticle = (id) => {
        if (id) {
            proxy.$router.push({ path: '/article', query: { id } })
        }
    }
</script>

<style scoped lang="scss">

</style>