import { createApp } from 'vue'
import App from './App.vue'
// router
import { router } from './route'
// axios
import axios from './axios'
import { notification } from 'ant-design-vue'
const globalNotify = function(message) {
    notification.open({
        message: '结果反馈',
        description: message,
        onClick: () => {
            // do nothing
        },
    });
} 

if (process.env.NODE_ENV === 'production') {
    console.debug = () => {}
    console.log = () => {}
}
console.log('运行环境: ' + process.env.NODE_ENV)

const app = createApp(App)

app.config.globalProperties.$axios = axios
app.config.globalProperties.$globalNotify = globalNotify

app.use(router).mount('#app')
