<template>
    <div>
        <nav-general-more v-if="subType === 'more'" :type="props.type"></nav-general-more>
        <nav-general-single v-if="subType === 'single'" :type="props.type"></nav-general-single>
    </div>
</template>

<script setup>
    import NavGeneralMore from './NavGeneralMore.vue'
    import NavGeneralSingle from './NavGeneralSingle.vue'
    import {
        onMounted,
        ref,
        getCurrentInstance
    } from 'vue'
    const { proxy } = getCurrentInstance()
    const props = defineProps({
        type: {
            type: String,
            required: true
        },
    })
    const subType = ref('')
    onMounted(() => {
        proxy.$axios.get(`/common-system-article-type/children?name=${encodeURIComponent(props.type)}`).then(res => {
            const sub = res.data.filter(item => item.label.indexOf('宣传图') < 0)
            subType.value = sub.length > 1 ? 'more' : 'single'
        })
    })
</script>

<style scoped>

</style>