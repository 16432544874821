<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: center; background: #FFF;">
            <div style="width: 100%; max-width: 1440px; padding: 60px 100px 100px 100px;">
                <div style="display: flex;">
                    <div style="width: 334%; margin-top: 50px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <div style="display: flex;">
                                <div style="width: 4px; height: 68px; background: #028848;"></div>
                                <div style="margin-left: 10px;">
                                    <p style="
                                        font-size: 20px;
                                        font-weight: 400;
                                        color: #282A29;
                                        line-height: 24px;
                                    ">EMPLOYMENT</p>
                                    <p style="
                                        margin-top: 6px;
                                        font-size: 32px;
                                        font-weight: 600;
                                        color: #282A29;
                                        line-height: 40px;
                                    ">就业信息</p>
                                </div>
                            </div>
                            <div @click="gotoEmployment('就业信息')" style="display: flex; align-items: center; cursor: pointer;">
                                <div style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #028848;
                                    line-height: 20px;
                                ">查看更多</div>
                                <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt="" style="width: 16px; height: 10px; margin-left: 8px;">
                            </div>
                        </div>
                        <div v-if="infos.competition.records[0]" 
                            @click="gotoArticle(infos.competition.records[0].id)"
                            class="hover-item" 
                            style="width: 100%; height: 166px; overflow: hidden; margin-top: 30px; cursor: pointer;">
                            <img class="hover-plus" :src="infos.competition.records[0].coverUrl" alt="" style="width: 100%; height: 100%; object-fit: cover;">
                        </div>
                        <div>
                            <div v-for="(record, index) in infos.competition.records" :key="index" @click="gotoArticle(record.id)" 
                                style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px; cursor: pointer;">
                                <div style="display: flex; align-items: center;">
                                    <div style="flex-shrink: 0;">
                                        <div style="width: 12px; height: 12px; border-bottom: 6px solid #028848; border-left: 6px solid #FFF; border-right: 6px solid #FFF;"></div>
                                        <div style="width: 12px; height: 12px; border-top: 6px solid #028848; border-left: 6px solid #FFF; border-right: 6px solid #FFF;"></div>
                                    </div>
                                    <div style="
                                        margin-left: 8px;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 1;
                                        font-size: 14px;
                                        font-weight: 600;
                                        color: #282A29;
                                        line-height: 22px;
                                    ">{{ record.title }}</div>
                                </div>
                                <div style="
                                    flex-shrink: 0;
                                    margin-left: 8px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #989898;
                                    line-height: 20px;
                                ">{{ record.createTime.split(' ')[0] }}</div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 40%;"></div>
                    <div style="width: 492%; overflow: hidden;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <div style="display: flex;">
                                <div style="width: 4px; height: 68px; background: #028848;"></div>
                                <div style="margin-left: 10px;">
                                    <p style="
                                        font-size: 20px;
                                        font-weight: 400;
                                        color: #282A29;
                                        line-height: 24px;
                                    ">ENROLLMENT</p>
                                    <p style="
                                        margin-top: 6px;
                                        font-size: 32px;
                                        font-weight: 600;
                                        color: #282A29;
                                        line-height: 40px;
                                    ">招生信息</p>
                                </div>
                            </div>
                            <div @click="gotoEmployment('招生信息')" style="display: flex; align-items: center; cursor: pointer;">
                                <div style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #028848;
                                    line-height: 20px;
                                ">查看更多</div>
                                <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt="" style="width: 16px; height: 10px; margin-left: 8px;">
                            </div>
                        </div>
                        <img v-if="infos.enrollment.records[checked]" 
                            @click="gotoArticle(infos.enrollment.records[checked] ? infos.enrollment.records[checked].id : '')"
                            :src="infos.enrollment.records[checked].coverUrl" alt="" 
                            style="width: 100%; height: 294px; margin-top: 30px; object-fit: cover; cursor: pointer;">
                        <div @click="gotoArticle(infos.enrollment.records[checked] ? infos.enrollment.records[checked].id : '')"
                            style="cursor: pointer;">
                            <div style="
                                text-align: center;
                                margin-top: 24px;
                                padding: 0 32px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                font-size: 18px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 24px;
                            ">{{ infos.enrollment.records[checked] ? infos.enrollment.records[checked].title : '' }}</div>
                            <div style="
                                margin-top: 16px;
                                padding: 0 32px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                font-size: 14px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 22px;
                            ">{{ infos.enrollment.records[checked] ? infos.enrollment.records[checked].intro : '' }}</div>
                            <div style="
                                margin-top: 16px;
                                padding: 0 32px;
                                text-align: center;
                                font-size: 14px;
                                font-weight: 400;
                                color: #989898;
                                line-height: 20px;
                            ">{{ infos.enrollment.records[checked] ? infos.enrollment.records[checked].createTime.split(' ')[0] : '' }}</div>
                        </div>
                        <div style="margin-top: 20px;">
                            <a-carousel arrows :after-change="toggleItem" autoplay>
                                <template #prevArrow>
                                    <img src="@/assets/slices/common_icon_arrow1_left_s@2x.png" class="custom-slick-arrow" style="left: 32px; z-index: 1; margin-top: -30px;" />
                                </template>
                                <template #nextArrow>
                                    <img src="@/assets/slices/common_icon_arrow1_right_s@2x.png" class="custom-slick-arrow" style="right: 32px; z-index: 1; margin-top: -30px;" />
                                </template>
                                <div v-for="item in infos.enrollment.records.length" :key="item">
                                    <div style="width: 100%; height: 56px;"></div>
                                </div>
                            </a-carousel>
                        </div>
                    </div>
                    <div style="width: 40%;"></div>
                    <div style="width: 334%; margin-top: 50px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <div style="display: flex;">
                                <div style="width: 4px; height: 68px; background: #028848;"></div>
                                <div style="margin-left: 10px;">
                                    <p style="
                                        font-size: 20px;
                                        font-weight: 400;
                                        color: #282A29;
                                        line-height: 24px;
                                    ">STORY</p>
                                    <p style="
                                        margin-top: 6px;
                                        font-size: 32px;
                                        font-weight: 600;
                                        color: #282A29;
                                        line-height: 40px;
                                    ">学子故事</p>
                                </div>
                            </div>
                            <div @click="gotoEmployment('学子故事')" style="display: flex; align-items: center; cursor: pointer;">
                                <div style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #028848;
                                    line-height: 20px;
                                ">查看更多</div>
                                <img src="@/assets/slices/common_icon_arrow3_go@2x.png" alt="" style="width: 16px; height: 10px; margin-left: 8px;">
                            </div>
                        </div>
                        <div v-if="infos.story.records[0]" class="hover-item" style="width: 100%; height: 166px; overflow: hidden; margin-top: 30px;">
                            <img class="hover-plus" :src="infos.story.records[0].coverUrl" alt="" style="width: 100%; height: 100%; object-fit: cover;">
                        </div>
                        <div>
                            <div v-for="(record, index) in infos.story.records" :key="index" @click="gotoArticle(record.id)"
                                style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px; cursor: pointer;">
                                <div style="display: flex; align-items: center;">
                                    <div style="flex-shrink: 0;">
                                        <div style="width: 12px; height: 12px; border-bottom: 6px solid #028848; border-left: 6px solid #FFF; border-right: 6px solid #FFF;"></div>
                                        <div style="width: 12px; height: 12px; border-top: 6px solid #028848; border-left: 6px solid #FFF; border-right: 6px solid #FFF;"></div>
                                    </div>
                                    <p style="
                                        margin-left: 8px;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 1;
                                        font-size: 14px;
                                        font-weight: 600;
                                        color: #282A29;
                                        line-height: 22px;
                                    ">{{ record.title }}</p>
                                </div>
                                <p style="
                                    flex-shrink: 0;
                                    margin-left: 8px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #989898;
                                    line-height: 20px;
                                ">{{ record.createTime.split(' ')[0] }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref } from 'vue';
import { Carousel as ACarousel } from 'ant-design-vue'
const { proxy } = getCurrentInstance()
const infos = ref({
    competition: {
        current: 1,
        size: 5,
        published: true,
        records: [],
        serverType: '招生就业/就业信息',
    },
    enrollment: {
        current: 1,
        size: 4,
        published: true,
        records: [],
        serverType: '招生就业/招生信息',
    },
    story: {
        current: 1,
        size: 5,
        published: true,
        records: [],
        serverType: '招生就业/学子故事',
    },
})
const checked = ref(0)
const getInfos = () => {
    for (let item in infos.value) {
        proxy.$axios.get('/common-system-article/page/pub', {
            params: { ...infos.value[item] }
        }).then(res => {
            infos.value[item] = res.data
        })
    }
}
const toggleItem = (index) => {
    checked.value = index
}
const gotoArticle = (id) => {
    if (id) {
        proxy.$router.push({ path: '/article', query: { id: id } })
    }
}
const gotoEmployment = (tag) => {
    proxy.$router.push({ path: '/enrollment', query: { tab: tag } })
}

onMounted(() => {
    getInfos()
})
</script>

<style scoped>

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    width: 56px;
    height: 56px;
    opacity: 0.3;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
    display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
    opacity: 1;
}

.ant-carousel :deep(.slick-dots button) {
    background-color: #E2E4E3;
}

.ant-carousel :deep(.slick-dots .slick-active button) {
    background-color: #028848;
}

</style>