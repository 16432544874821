<template>
    <div style="width: 100%; display: flex; justify-content: center; align-items: center; background-color: #028848;">
        <div style="max-width: 1440px; width: 100%; padding: 40px 100px;">
            <div style="display: flex; justify-content: space-between; border-bottom: 1px solid rgb(255, 255, 255, 0.19); padding-bottom: 40px;">
                <div style="display: flex;">
                    <!-- 左边logo -->
                    <img src="@/assets/slices/bottom_logo@2x.png" alt="" style="height: 60px; width: 266px;">
                    <!-- 中间文字部分 -->
                    <div style="margin-left: 60px;">
                        <div style="display: flex;">
                            <img src="@/assets/slices/bottom_icon_phone@2x.png" alt="" style="height: 16px; width: 16px;">
                            <p style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 16px;
                                    margin-left: 8px;
                                ">028-81275176（招办） 0838-6532666 / 0838-6532669（院办）</p>
                        </div>
                        <div style="display: flex; margin-top: 20px;">
                            <div style="display: flex;">
                                <img src="@/assets/slices/bottom_icon_location@2x.png" alt="" style="height: 16px; width: 16px;">
                                <p style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 16px;
                                    margin-left: 8px;
                                ">四川省德阳市罗江区大学北路95号</p>
                            </div>
                            <div style="display: flex; margin-left: 40px;">
                                <img src="@/assets/slices/bottom_icon_web@2x.png" alt="" style="height: 16px; width: 16px;">
                                <p style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 16px;
                                    margin-left: 8px;
                                ">www.dynky.cn</p>
                            </div>
                            <div style="display: flex; margin-left: 40px;">
                                <img src="@/assets/slices/qq@2x.png" alt="" style="height: 16px; width: 16px;">
                                <p style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 16px;
                                    margin-left: 8px;
                                ">80097215</p>
                            </div>
                        </div>
                        <a href="https://www.sc.gov.cn/" target="_black">
                            <img src="@/assets/slices/p_bottom_link@2x.png" alt="" style="height: 34px; width: 200px; margin-top: 30px; cursor: pointer;">
                        </a>
                    </div>
                </div>
                <!-- 右边二维码 -->
                <div style="text-align: center;">
                    <img src="@/assets/slices/bottom_code@2x.png" alt="" style="height: 100px; width: 100px;">
                    <p style="
                            font-size: 14px;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 20px;
                            margin-top: 8px;
                        ">欢迎关注德阳农业科技职业学院公众号</p>
                </div>
            </div>
            <div style="margin-top: 23px; display: flex; justify-content: space-between;">
                <p style="
                    font-size: 14px;
                    font-weight: 400;
                    color: #DEDEDE;
                    line-height: 20px;
                ">版权所有 © 德阳农业科技职业学院&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank" style="
                    font-size: 14px;
                    font-weight: 400;
                    color: #DEDEDE;
                    line-height: 20px;
                ">蜀ICP备2020029462号-2</a></p>
                <p style="
                    font-size: 14px;
                    font-weight: 400;
                    color: #DEDEDE;
                    line-height: 20px;
                ">友情链接：<a href="" target="_blank" style="
                    font-size: 14px;
                    font-weight: 400;
                    color: #DEDEDE;
                    line-height: 20px;
                ">德阳中艺科技职业学校</a></p>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>