<template>
    <div>
        <img v-if="source" :src="source" alt="" style="width: 100%; height: 460px; object-fit: cover;">
        <div v-else style="width: 100%; height: 460px;"></div>
    </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue'
const { proxy } = getCurrentInstance()
const props = defineProps({
    type: {
        type: String,
    }
})
const emits = defineEmits(['onload'])
const source = ref('')
const getPic = () => {
    proxy.$axios.get('/common-system-article/page/pub', {
        params: {
            current: 1,
            size: 1,
            published: true,
            serverType: props.type,
        },
    }).then(res => {
        if (res.data.records && res.data.records[0]) {
            source.value = res.data.records[0].coverUrl
            emits('onload')
        } else {
            emits('onload')
        }
    })
}
onMounted(() => {
    getPic()
})
</script>

<style scoped>

</style>