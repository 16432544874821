<!-- 对外交流 -->
<template>
    <div>
        <MainPic :type="`${props.type}/宣传图`" />
        <div style="display: flex; justify-content: center; align-items: center; background: #F6F8F7;">
            <div style="width: 100%; max-width: 1440px; margin-top: 50px; padding: 0 100px;">
                <div style="display: flex; justify-content: space-between;">
                    <div style="display: flex;">
                        <div style="width: 4px; height: 68px; background: #028848;"></div>
                        <div style="margin-left: 10px;">
                            <p style="
                                font-size: 20px;
                                font-weight: 400;
                                color: #282A29;
                                line-height: 24px;
                            ">{{ articleType.en }}</p>
                            <p style="
                                margin-top: 6px;
                                font-size: 32px;
                                font-weight: 600;
                                color: #282A29;
                                line-height: 40px;
                            ">{{ articleType.label }}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-for="(record, index) in page.records" :key="index" @click="gotoArticle(record.id)"
                        class="hover-item"
                        style="background: #FFF; padding: 24px; display: flex; justify-content: space-between; margin-top: 20px; cursor: pointer;">
                        <div style="width: 224%;">
                            <div style="width: 100%; height: 150px; overflow: hidden;">
                                <img class="hover-plus" :src="record.coverUrl" alt=""
                                    style="width: 100%; height: 100%; object-fit: cover;">
                            </div>
                            <div
                                style="height: 52px; width: 73px; background: #028848; opacity: 0.7; margin-top: -64px; padding: 6px 0 0 12px;">
                                <p style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: #FFFFFF;
                                    line-height: 22px;
                                ">{{ record.createTime ? (record.createTime.split(' ')[0].split('-')[1] + '/' +
                                    record.createTime.split(' ')[0].split('-')[2]) : '' }}</p>
                                <p style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 18px;
                                ">{{ record.createTime ? (record.createTime.split(' ')[0].split('-')[0]) : '' }}</p>
                            </div>
                        </div>
                        <div style="width: 24%;"></div>
                        <div style="width: 944%;">
                            <p class="hover-color" style="
                                font-size: 18px;
                                font-weight: 600;
                                line-height: 24px;
                            ">{{ record.title }}</p>
                            <div style="height: 78px;">
                                <p style="
                                    margin-top: 12px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 3;
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #282A29;
                                    line-height: 26px;
                                ">{{ record.intro }}</p>
                            </div>
                            <p style="
                                margin-top: 16px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #989898;
                                line-height: 20px;
                            ">{{ record.watched + ' 浏览' }}</p>
                        </div>
                    </div>
                    <div style="text-align: center; margin: 40px auto 60px auto;">
                        <a-pagination v-model:current="page.current" v-model:page-size="page.size" :total="page.total"
                            :showSizeChanger="false" :show-total="total => `总共 ${total} 条`" @change="pageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import MainPic from '@/components/MainPic.vue'
    import { ref, onMounted, getCurrentInstance } from 'vue'
    import { Pagination as APagination } from 'ant-design-vue'
    const { proxy } = getCurrentInstance()
    const props = defineProps({
        type: {
            type: String,
            required: true
        },
    })
    const page = ref({
        current: 1,
        size: 8,
        total: 0,
        serverType: props.type + '/文章列表',
        published: true,
    })
    const articleType = ref({})
    const getNews = () => {
        proxy.$axios.get('/common-system-article/page/pub', {
            params: { ...page.value, records: null }
        }).then(res => {
            page.value = res.data
        })
    }
    const gotoArticle = (id) => {
        if (id) {
            proxy.$router.push({ path: '/article', query: { id } })
        }
    }
    // eslint-disable-next-line
    const pageChange = (current, size) => {
        getNews()
    }
    onMounted(() => {
        getNews()
        proxy.$axios.get(`/common-system-article-type/name?name=${encodeURIComponent(props.type)}`).then(res => {
            articleType.value = res.data
        })
    })
</script>