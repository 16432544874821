/// 文章业务类型 coverSize 102400 Byte
// 新闻
const news = {
    news: { name: '学校新闻', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '学校新闻', uri: '/news' }] },    
    video: { name: '学校影像', title: true, cover: true, coverSize: 1024000, video: true, content: true, remark: true, nav: [{ name: '学校新闻', uri: '/news' }, { name: '学校影像', uri: '/news', query: { tab: 1 } }] },
    notice: { name: '学校通知', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '学校新闻', uri: '/news' }, { name: '通知公告', uri: '/news', query: { tab: 2 } }] },
    subject: { name: '学校专题', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '学校新闻', uri: '/news' }, { name: '专题管理', uri: '/news', query: { tab: 3 } }] },
    college: { name: '学院动态', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '院系设计', uri: '/college/list' }, { name: '专业课程' }] },
    dept: { name: '部门动态', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '机构设置', uri: '/dept' }] },
}
// 简介
const intro = {
    school: { name: '学校简介', title: true, video: true, content: true, remark: true, nav: [{ name: '学校概况', uri: '/override' }, { name: '学校简介' }] },
    college: { name: '学院简介', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '院系设计', uri: '/college/list' }, { name: '学院简介' }] },
    department: { name: '院系简介', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '院系设计', uri: '/college/list' }, { name: '院系简介' }] },
    majorIntro: { name: '专业简介', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '院系设计', uri: '/college/list' }, { name: '专业简介' }] },
    majorDirection: { name: '专业方向', title: true, content: true, remark: true, nav: [{ name: '院系设计', uri: '/college/list' }, { name: '专业方向' }] },
    majorCourse: { name: '专业课程', title: true, content: true, remark: true, nav: [{ name: '院系设计', uri: '/college/list' }, { name: '专业课程' }] },
    majorEmployment: { name: '专业前景', title: true, content: true, remark: true, nav: [{ name: '院系设计', uri: '/college/list' }, { name: '专业前景' }] },
}
// 探索发现
const discovery = {
    edu: { name: '教育教学', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '探索研究', uri: '/discovery' }, { name: '教育教学', uri: '/discovery', query: { tab: 0 } }] },
    science: { name: '科学研究', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '探索研究', uri: '/discovery' }, { name: '科学研究', uri: '/discovery', query: { tab: 1 } }] },
}
// 校园风光
const scenery = {
    school: { name: '校园风光', cover: true, coverSize: 1024000, remark: true },
    classroom: { name: '教室环境', cover: true, coverSize: 1024000, remark: true },
    canteen: { name: '食宿环境', cover: true, coverSize: 1024000, remark: true },
}
// 就业创业
const employment = {
    create: { name: '就业创业', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '就业创业', uri: '/employment' }] },
    story: { name: '学子故事', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '就业创业', uri: '/employment' }, { name: '学子故事', uri: '/employment', query: { tab: 1 } }] },
}
// 招生在线
const enrollment = {
    intro: { name: '招生简章', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '招生在线', uri: '/enrollment' }, { name: '招生简章', uri: '/enrollment', query: { tab: 0 } }] },
    guide: { name: '入学指南', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '招生在线', uri: '/enrollment' }, { name: '入学指南', uri: '/enrollment', query: { tab: 1 } }] },
}
// 概览
const override = {
    leader: { name: '学校领导', title: true, cover: true, coverSize: 1024000, post: true, content: true, remark: true, nav: [{ name: '学校概况', uri: '/override' }, { name: '学校领导', uri: '/override/leader' }] },
    care: { name: '领导关怀', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '学校概况', uri: '/override' }, { name: '领导关怀', uri: '/override/solicitude' }] },
    constitution: { name: '学校章程', title: true, cover: true, coverSize: 1024000, content: true, nav: [{ name: '学校概况', uri: '/override' }, { name: '学校章程' }] },
    concept: { name: '办学理念', title: true, content: true, nav: [{ name: '学校概况', uri: '/override' }, { name: '办学理念' }] },
    banner: { name: '首页BANNER', cover: true, coverSize: 1024000, link: true, remark: true },
	bannerMob: { name: '首页BANNERMOB', cover: true, coverSize: 1024000, link: true, remark: true },
}
const advpic = {
    override: { name: '学院概况宣传图', cover: true, coverSize: 1024000, },
    news: { name: '农科新闻宣传图', cover: true, coverSize: 1024000, },
    college: { name: '院系设计宣传图', cover: true, coverSize: 1024000, },
    discovery: { name: '探索研究宣传图', cover: true, coverSize: 1024000, },
    employment: { name: '就业创业宣传图', cover: true, coverSize: 1024000, },
    enrollment: { name: '招生在线宣传图', cover: true, coverSize: 1024000, },
    dept: { name: '机构设置宣传图', cover: true, coverSize: 1024000, },
    building: { name: '党建专栏宣传图', cover: true, coverSize: 1024000, },
    info: { name: '信息公开宣传图', cover: true, coverSize: 1024000, },
    communication: { name: '对外交流宣传图', cover: true, coverSize: 1024000, },
}
const building = {
    history: { name: '党史学习', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '党建专栏', uri: '/build' }, { name: '党史学习', uri: '/build', query: { tab: 0 } }] },
    event: { name: '党建活动', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '党建专栏', uri: '/build' }, { name: '党建活动', uri: '/build', query: { tab: 1 } }] },
    guide: { name: '入党指导', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '党建专栏', uri: '/build' }, { name: '入党指导', uri: '/build', query: { tab: 2 } }] },
}
const communit = {
    communication: { name: '对外交流', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '对外交流', uri: '/communication' }] },
    info: { name: '信息公开', title: true, cover: true, coverSize: 1024000, content: true, remark: true, nav: [{ name: '信息公开', uri: '/info' }] },
}

const getConfigByName = (name) => {
    for (let item in news) {
        if (news[item].name === name) {
            return news[item]
        }
    }
    for (let item in intro) {
        if (intro[item].name === name) {
            return intro[item]
        }
    }
    for (let item in discovery) {
        if (discovery[item].name === name) {
            return discovery[item]
        }
    }
    for (let item in scenery) {
        if (scenery[item].name === name) {
            return scenery[item]
        }
    }
    for (let item in employment) {
        if (employment[item].name === name) {
            return employment[item]
        }
    }
    for (let item in enrollment) {
        if (enrollment[item].name === name) {
            return enrollment[item]
        }
    }
    for (let item in override) {
        if (override[item].name === name) {
            return override[item]
        }
    }
    for (let item in advpic) {
        if (advpic[item].name === name) {
            return advpic[item]
        }
    }
    for (let item in building) {
        if (building[item].name === name) {
            return building[item]
        }
    }
    for (let item in communit) {
        if (communit[item].name === name) {
            return communit[item]
        }
    }
    return {}
}

export { news, intro, discovery, scenery, employment, enrollment, override, advpic, building, communit, getConfigByName }
